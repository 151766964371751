import { connect } from "react-redux"
import MobileNavigation from "highline/components/application/mobile_navigation"
import {
  navigationParentTileClicked,
  navigationSubNavTileClickedAsync,
} from "highline/redux/actions/navigation_actions"
import { logoutAsync } from "highline/redux/actions/auth_actions"
import {
  getImgixUrl,
  getField,
  getContentfulId,
  getImageAspectRatio,
  getObjectByFirstField,
} from "highline/utils/contentful/contentful_helper"

const mapStateToProps = (state) => {
  const activeNav = state.getIn(["navigation", "activeNav"])
  const contentfulData = state.getIn(["contentful", "globals"])
  const navData = getField(getObjectByFirstField(contentfulData, "Navigation"), "content")
  const contentfulNavItems =
    getField(getObjectByFirstField(navData, "Live Navigation"), "navigationItems") || []
  const subNavItems = []

  contentfulNavItems.forEach((item) => {
    const displayName = getField(item, "fullDisplayName")
    if (displayName === activeNav) {
      const navItemGroups = getField(item, "navigationColumns")
      navItemGroups.forEach((group) =>
        subNavItems.push(...getField(group, "navigationItems").toArray())
      )
    }
  })

  return {
    activeNav: activeNav,
    activePath: state.getIn(["navigation", "activePath"]),
    contentfulNavItems:
      getField(getObjectByFirstField(navData, "Live Navigation"), "navigationItems") || [],
    subNavItems: subNavItems,
    isLoggedIn: state.getIn(["auth", "isLoggedIn"]),
    isMobileNavOpen: state.getIn(["navigation", "isOpen"]),
    shouldCollapse: state.getIn(["navigation", "shouldCollapse"]),
    view: state.getIn(["navigation", "view"]),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleClick: () => {},
    handleLogOut: (e, name, level, link) => {
      e.preventDefault()
      dispatch(logoutAsync(name, level, link))
    },
    onClickParentTile: (name, path) => {
      if (document !== undefined) {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      dispatch(navigationParentTileClicked(name, path))
    },
    onClickSubNavTile: (name, link) => {
      dispatch(navigationSubNavTileClickedAsync(link, name))
    },
  }
}

const MobileNavigationContainer = connect(mapStateToProps, mapDispatchToProps)(MobileNavigation)

export default MobileNavigationContainer
