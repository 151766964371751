import { useSelector } from "react-redux"
import { useSelectIsLoggedIn } from "highline/selectors/auth/auth_selectors"
import {
  applyUserAuthStatusDisplayRule,
  applyDeviceDisplayRule,
} from "highline/utils/contentful/display_rule_helper"
import { useViewportSize } from "highline/hooks/use_viewport_size"

export const useContentBlockDisplayRules = (rules = {}) => {
  const isUserLoggedIn = useSelector(useSelectIsLoggedIn)
  const { isSmartphone } = useViewportSize()

  const { userAuthStatusDisplayRule, deviceDisplayRule } = rules
  return (
    applyUserAuthStatusDisplayRule(isUserLoggedIn, userAuthStatusDisplayRule) &&
    applyDeviceDisplayRule(isSmartphone, deviceDisplayRule)
  )
}
