import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "highline/styles/components/watermark.module.css"

const watermarkWrapper = (svg, placement, type) => {
  return (
    <div className={classNames(styles.watermarkWrapper, styles[placement], styles[type])}>
      {svg}
    </div>
  )
}

const Watermark = ({ svg, placement, type }) => {
  return watermarkWrapper(svg, placement, type)
}

Watermark.propTypes = {
  svg: PropTypes.element,
  placement: PropTypes.string,
  type: PropTypes.string,
}

export default Watermark
