import React from "react"
import PropTypes from "prop-types"
import { useRouter } from "next/router"
import Link from "highline/components/secure_link"
import List from "highline/components/list"
import styles from "highline/styles/components/application/account_dropdown_list.module.css"
import { paths } from "highline/utils/navigate"

const accountList = (
  <List>
    <a href={paths.get("account")}>Orders & Returns</a>
    <a href={paths.get("fitPreferences")}>My Fit</a>
    <a href={paths.get("savedItems")}>Saved Items</a>
  </List>
)
const helpList = (
  <List>
    <Link href={paths.get("help")} target="blank">
      Help
    </Link>
    <Link href={paths.get("returns")} target="blank">
      Return FAQ
    </Link>
    <Link href={paths.get("returnLabel")}>Print Return Label</Link>
    <Link href={paths.get("contactUs")} target="blank">
      Contact Us
    </Link>
  </List>
)

const AccountDropDownList = ({ isLoggedIn, onClickLogout }) => {
  const { asPath } = useRouter()
  const signInUrl = `${paths.get("signIn")}?redirect_to=${asPath}`

  return (
    <div className={styles.accountDropdownContainer}>
      {isLoggedIn && accountList}
      {helpList}
      <List>
        {isLoggedIn ? (
          <button aria-label="Sign out" onClick={onClickLogout}>
            Sign Out
          </button>
        ) : (
          <Link href={signInUrl} aria-label="Navigate to sign-in page">
            Sign In
          </Link>
        )}
      </List>
    </div>
  )
}

AccountDropDownList.propTypes = {
  isLoggedIn: PropTypes.bool,
  onClickLogout: PropTypes.func,
}

AccountDropDownList.defaultProps = {
  isLoggedIn: false,
  onClickLogout: () => {},
}

export default AccountDropDownList
