import React from "react"
import PropTypes from "prop-types"
import { useRouter } from "next/router"
import Tooltip from "highline/components/tooltip/tooltip"
import AccountDropDownList from "highline/components/application/account_dropdown_list"
import styles from "highline/styles/components/application/account_content.module.css"
import Button from "highline/components/button"
import Cta from "highline/components/cta"
import Link from "highline/components/secure_link"
import { paths } from "highline/utils/navigate"
import { AccountIcon } from "highline/components/icons"

const mobileSignedOut = (signInUrl) => (
  <Cta layout="plain-text" aria-label="Navigate to sign-in page" href={signInUrl}>
    Sign In
  </Cta>
)

const AccountContent = ({
  firstName,
  isLoggedIn,
  isAccountDropdownOpen,
  isTablet,
  onClickLogout,
  onMouseEnterAccount,
  onMouseLeaveAccount,
  onClickAccount,
}) => {
  const { asPath } = useRouter()
  const signInUrl = `${paths.get("signIn")}?redirect_to=${asPath}`

  const accountOrSignInLink = (isLoggedIn) =>
    isLoggedIn ? (
      <a href={paths.get("account")} className={styles.accountButtonText} tabIndex="-1">
        {`Hi, ${firstName}`}
      </a>
    ) : (
      <Link href={signInUrl} className={styles.accountButtonText} tabIndex="-1">
        Sign In
      </Link>
    )

  return isTablet && !isLoggedIn ? (
    mobileSignedOut(signInUrl)
  ) : (
    <div
      className={styles.authWrapper}
      onMouseEnter={onMouseEnterAccount}
      onMouseLeave={onMouseLeaveAccount}
    >
      <Tooltip
        layout="account"
        isOpen={isAccountDropdownOpen}
        dropdown
        target={
          <Button
            aria-expanded={isAccountDropdownOpen}
            aria-label={isLoggedIn ? "Open Account Dropdown" : "Open Sign In Dropdown"}
            onClick={onClickAccount}
            layout="plain-text"
          >
            {!isTablet && accountOrSignInLink(isLoggedIn)}
            {isTablet && <AccountIcon />}
          </Button>
        }
      >
        <AccountDropDownList isLoggedIn={isLoggedIn} onClickLogout={onClickLogout} />
      </Tooltip>
    </div>
  )
}

AccountContent.propTypes = {
  firstName: PropTypes.string,
  isAccountDropdownOpen: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isTablet: PropTypes.bool,
  onClickAccount: PropTypes.func,
  onClickLogout: PropTypes.func,
  onMouseEnterAccount: PropTypes.func,
  onMouseLeaveAccount: PropTypes.func,
}

AccountContent.defaultProps = {
  firstName: "",
  isAccountDropdownOpen: false,
  isLoggedIn: false,
  isTablet: false,
  onClickAccount: () => {},
  onClickLogout: () => {},
  onMouseEnterAccount: () => {},
  onMouseLeaveAccount: () => {},
}

export default AccountContent
