import React, { useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { detectSmartphoneWidth } from "highline/utils/viewport"
import styles from "highline/styles/components/horizontal_scroll_area.module.css"

const ContentfulHorizontalScroll = ({
  children,
  desktopItems,
  desktopSpaceBetween,
  mobileItems,
  mobileSpaceBetween,
  partialRight,
}) => {
  const desktopRightSideBuffer = 5
  const mobileRightSideBuffer = 15
  const desktopPercentageWidth = 1 / desktopItems
  const mobilePercentageWidth = 1 / mobileItems

  // styles default to mobile experience
  const [scrollItemStyle, setScrollItemStyle] = React.useState({})

  // change styles depending on the desktop vs mobile
  const adjustHorizontalScrollStyles = () => {
    const isSmartphone = detectSmartphoneWidth()
    const targetRightSideBuffer = isSmartphone ? mobileRightSideBuffer : desktopRightSideBuffer
    const partialRightAdjustment = partialRight ? targetRightSideBuffer : 0
    const targetPercentageWidth = isSmartphone ? mobilePercentageWidth : desktopPercentageWidth
    const targetSpaceBetween = isSmartphone ? mobileSpaceBetween : desktopSpaceBetween
    const adjustedWidth = targetPercentageWidth * (100 - partialRightAdjustment)
    const itemStyles = {
      width: `calc(${adjustedWidth}% - ${targetSpaceBetween}px)`,
      marginRight: `${targetSpaceBetween}px`,
    }
    setScrollItemStyle(itemStyles)
  }

  useEffect(() => {
    // handle initial load styling
    adjustHorizontalScrollStyles()

    // adjust styles on window resize
    window.addEventListener("resize", adjustHorizontalScrollStyles)
    return () => {
      window.removeEventListener("resize", adjustHorizontalScrollStyles)
    }
  }, [detectSmartphoneWidth])

  return (
    <div className={classNames(styles.component, styles.contentfulHorizontalScroll)}>
      {React.Children.map(children, (child) => (
        <div className={styles.scrollItem} style={scrollItemStyle}>
          {child}
        </div>
      ))}
    </div>
  )
}

ContentfulHorizontalScroll.propTypes = {
  children: PropTypes.node,
  desktopItems: PropTypes.number,
  desktopSpaceBetween: PropTypes.number,
  mobileItems: PropTypes.number,
  mobileSpaceBetween: PropTypes.number,
  partialRight: PropTypes.bool,
}

ContentfulHorizontalScroll.defaultProps = {
  desktopItems: 1,
  desktopSpaceBetween: 0,
  mobileItems: 1,
  mobileSpaceBetween: 0,
  partialRight: false,
}

export default ContentfulHorizontalScroll
