import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Link from "highline/components/secure_link"
import { getClientSideLink } from "highline/utils/link"
import styles from "highline/styles/components/button.module.css"

const Cta = ({
  align,
  children,
  className,
  ctaRef,
  externalLink,
  href,
  isLink,
  layout,
  onClick,
  rounded,
  size,
  shouldOpenInNewTab,
  ...other
}) => {
  const clientSideLink = getClientSideLink(href)
  const props = {
    className: classNames(
      "component",
      "cta-component",
      styles.component,
      className,
      styles[align],
      styles[layout],
      styles[size],
      isLink && styles.link,
      rounded && styles.rounded
    ),
    onClick: onClick,
    ref: ctaRef,
    target: shouldOpenInNewTab ? "_blank" : "_self",
    rel: "noreferrer",
    ...other,
  }
  const anchorTag = (
    <a href={externalLink ? href : clientSideLink.get("as")} {...props}>
      {children}
    </a>
  )

  if (externalLink || !href) {
    return anchorTag
  }

  return (
    <Link as={clientSideLink.get("as")} href={clientSideLink.get("href")} {...props}>
      {children}
    </Link>
  )
}

Cta.propTypes = {
  align: PropTypes.oneOf(["block", "stretch", "inline"]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  ctaRef: PropTypes.func,
  externalLink: PropTypes.bool,
  href: PropTypes.string,
  isLink: PropTypes.bool,
  layout: PropTypes.oneOf([
    "primary",
    "primary-outline",
    "primary-transparent",
    "secondary",
    "secondary-outline",
    "secondary-transparent",
    "alternate",
    "alternate-outline",
    "alternate-transparent",
    "cancel",
    "cancel-link",
    "warning",
    "warning-link",
    "disabled-style",
    "transparent",
    "link",
    "plain-text",
    "link-underlined",
  ]),
  onClick: PropTypes.func,
  rounded: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  shouldOpenInNewTab: PropTypes.bool,
}

Cta.defaultProps = {
  align: "block",
  externalLink: false,
  isLink: false,
  layout: "primary",
  onClick: () => {},
  rounded: false,
  size: "medium",
  shouldOpenInNewTab: false,
}

export default Cta
