import React, { useState } from "react"
import classNames from "classnames"
import ImmutablePropTypes from "react-immutable-proptypes"
import { Map } from "immutable"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Cta from "highline/components/cta"
import { getField } from "highline/utils/contentful/contentful_helper"
import PropTypes from "prop-types"
import { htmlSanitizer } from "highline/utils/contentful/htmlSanitizer"
import styles from "highline/styles/components/contentful/contentful_editorial.module.css"
import { useIsFirstRender } from "highline/hooks/use_is_first_render"

const Editorial = ({ editorialComponent, callbackFn }) => {
  const isFirstRender = useIsFirstRender()

  const editorialStyling = {
    textAlign: getField(editorialComponent, "textAlign"),
  }

  const layout = getField(editorialComponent, "layout")

  if (!isFirstRender) {
    return null
  }

  return (
    <div
      className={classNames(
        "component",
        "contentful-editorial-component",
        styles.contentSection,
        layout ? styles[layout] : styles["default"]
      )}
      style={editorialStyling}
    >
      {getField(editorialComponent, "showTitle") && (
        <div className={styles.title}>{getField(editorialComponent, "title")}</div>
      )}
      {getField(editorialComponent, "body") && (
        <div className={styles.richText}>
          {documentToReactComponents(
            getField(editorialComponent, "body").toJS(),
            htmlSanitizer(callbackFn)
          )}
        </div>
      )}
      {getField(editorialComponent, "callToAction") &&
        getField(editorialComponent, "callToActionUrl") && (
          <Cta
            className={styles.cta}
            align="inline"
            layout={getField(editorialComponent, "callToActionStyle")}
            rounded={getField(editorialComponent, "callToActionRounded")}
            href={getField(editorialComponent, "callToActionUrl")}
          >
            {getField(editorialComponent, "callToAction")}
          </Cta>
        )}
    </div>
  )
}

Editorial.propTypes = {
  callbackFn: PropTypes.func,
  editorialComponent: ImmutablePropTypes.map,
}

Editorial.defaultProps = {
  callbackFn: () => {},
  editorialComponent: Map(),
}

export default Editorial
