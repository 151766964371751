import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { fromJS } from "immutable"
import classNames from "classnames"
import Swatch from "highline/components/swatch"
import styles from "highline/styles/components/scrolling_swatches.module.css"

class ScrollingSwatches extends React.PureComponent {
  render() {
    const {
      className,
      checkedSwatchIndex,
      onSwatchClick,
      onSwatchMouseEnter,
      onSwatchMouseLeave,
      size,
      swatches,
      width,
    } = this.props

    return (
      <div className={classNames(styles.component, styles[size], className)}>
        {swatches
          .filter((item) => !!item.get("colorName"))
          .map((swatch, index) => (
            <Swatch
              key={index}
              checked={index === checkedSwatchIndex}
              className={classNames(styles.swatch)}
              imageUrl={swatch.get("swatchImageUrl")}
              layout={size}
              width={width}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onSwatchClick(index)
              }}
              onMouseEnter={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onSwatchMouseEnter(index)
              }}
              onMouseLeave={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onSwatchMouseLeave(index)
              }}
              onKeyPress={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onSwatchClick(index)
              }}
              tabIndex="0"
              role="button"
            />
          ))}
      </div>
    )
  }
}

ScrollingSwatches.propTypes = {
  className: PropTypes.string,
  checkedSwatchIndex: PropTypes.number,
  onSwatchClick: PropTypes.func,
  onSwatchMouseEnter: PropTypes.func,
  onSwatchMouseLeave: PropTypes.func,
  size: PropTypes.string,
  swatches: ImmutablePropTypes.list,
  width: PropTypes.string,
}

ScrollingSwatches.defaultProps = {
  className: "",
  checkedSwatchIndex: 0,
  onSwatchClick: () => {},
  onSwatchMouseEnter: () => {},
  onSwatchMouseLeave: () => {},
  size: "default",
  swatches: fromJS([]),
  width: "default",
}

export default ScrollingSwatches
