import { useMemo, memo } from "react"
import ImmutablePropTypes from "react-immutable-proptypes"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useRouter } from "next/router"
import { Map } from "immutable"
import { renderContentfulComponent } from "highline/utils/contentful/component_helper"
import {
  getBlockStyling,
  getContentArray,
  getCommonFormatting,
} from "highline/utils/contentful/page_styling_helper"
import { getClientSideLink } from "highline/utils/link"
import { getField, getContentType } from "highline/utils/contentful/contentful_helper"
import Fade from "react-reveal/Fade"
import styles from "highline/styles/components/contentful/contentful_content_block.module.css"
import { useContentfulProductTileFilter } from "highline/hooks/use_contentful_product_tile_filter"
import { useContentBlockDisplayRules } from "highline/hooks/use_content_block_display_rules"
import { useViewportSize } from "highline/hooks/use_viewport_size"

const ContentBlock = ({ blockComponent, callbackFn }) => {
  const { isSmartphone } = useViewportSize()

  const router = useRouter()
  const format = useMemo(() => getCommonFormatting(blockComponent), [blockComponent])

  //Style the content block differently for Desktop and Mobile
  const contentBlockStyling = getBlockStyling(isSmartphone, format)

  //Set the Content Block's nested items to be normal order or reversed order based on screen size and mobileContentOrder input
  const contentArray = useMemo(
    () => getContentArray(isSmartphone, format.mobileContentOrder, blockComponent),
    [isSmartphone, format, blockComponent]
  )

  const components = useContentfulProductTileFilter(contentArray)

  // Sometimes children need info set in the parent component, do that here
  const supplementedContentArray = useMemo(
    () =>
      components.map((content) => {
        // Add percentageWidth for image content
        if (getContentType(content) === "heroImage" || getContentType(content) === "storyPod") {
          return content.mergeDeep({
            fields: {
              desktopPercentageWidth: 1 / format.desktopItemsPerRow,
              mobilePercentageWidth: 1 / format.mobileItemsPerRow,
            },
          })
        }

        return content
      }),
    [components, format]
  )

  const handleContentBlockEnter = (e, contentBlockLink) => {
    if (e.key == "Enter") {
      contentBlockLink && navigateToContentBlockLink(contentBlockLink)
    }
  }

  const navigateToContentBlockLink = (link) => router.push(link.get("href"), link.get("as"))

  const id = getField(blockComponent, "id")
  const targetUrl = getField(blockComponent, "targetUrl")
  const contentBlockLink = targetUrl ? getClientSideLink(targetUrl) : null
  const showFade = getField(blockComponent, "showFade")

  const userAuthStatusDisplayRule = getField(blockComponent, "userAuthStatusDisplayRule")
  const deviceDisplayRule = getField(blockComponent, "deviceDisplayRule")

  const shouldShowComponent = useContentBlockDisplayRules({
    userAuthStatusDisplayRule,
    deviceDisplayRule,
  })

  return !shouldShowComponent ? null : (
    <div
      className={classNames(styles.contentBlockWrapper, contentBlockLink && styles.clickable)}
      tabIndex={contentBlockLink ? "0" : "-1"}
      role="link"
      aria-label={contentBlockLink && `Navigate to ${contentBlockLink}`}
      onClick={() => {
        contentBlockLink && navigateToContentBlockLink(contentBlockLink)
      }}
      onKeyDown={(e) => {
        handleContentBlockEnter(e, contentBlockLink)
      }}
    >
      <div className="contentful-block-component" style={contentBlockStyling} id={id}>
        {supplementedContentArray &&
          supplementedContentArray.map((component, index) => {
            return showFade ? (
              <Fade key={index}>{renderContentfulComponent(component, callbackFn, index)}</Fade>
            ) : (
              renderContentfulComponent(component, callbackFn, index)
            )
          })}
      </div>
    </div>
  )
}

ContentBlock.propTypes = {
  blockComponent: ImmutablePropTypes.map,
  callbackFn: PropTypes.func,
}

ContentBlock.defaultProps = {
  blockComponent: Map(),
  callbackFn: () => {},
}

const MemoizedContentBlock = memo(ContentBlock)
MemoizedContentBlock.displayName = "ContentBlock"

export default MemoizedContentBlock
