import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import classNames from "classnames"
import Button from "highline/components/button"
import Link from "highline/components/secure_link"
import SearchContainer from "highline/containers/search_container"
import DesktopNavigationContainer from "highline/containers/desktop_navigation_container"
import Logo from "highline/svg/icons/bonobos-logo-dark.svg"
import AccountContent from "highline/components/application/account_content"
import HeaderV2MenuItem from "highline/components/application/header_v2_menu_item"
import { getScrollTop } from "highline/utils/viewport"
import { ImmutableLoadingBar as LoadingBar } from "react-redux-loading-bar"
import { CartIcon, HamburgerIcon, SearchIcon } from "highline/components/icons"
import { getField } from "highline/utils/contentful/contentful_helper"
import { environmentVariables as cssEnvVars } from "highline/css-env-variables"
import { useViewportSize } from "highline/hooks/use_viewport_size"

import styles from "highline/styles/components/application/header_v2.module.css"
import { useEffect } from "react"
import { useCallback } from "react"
import { useState } from "react"

export const PROMO_PAGE_LINK_TEXT = "Get 25% Off"

const NAV_OPEN_DELAY = 250
const NAV_DISPLAY_DELAY = 100
const LOADING_BAR_MAX_PROGRESS = 95

function HeaderV2({
  activeNav,
  cartCount,
  firstName,
  globalMessageHeight,
  isLoggedIn,
  isAccountDropdownOpen,
  isDesktopNavOpen,
  isHeaderMinified,
  isTextLight,
  isTransparentOnLoad,
  contentfulNavItems,
  onMouseEnterAccount,
  onClickAccount,
  onClickCart,
  onClickMenuItem,
  onClickLogout,
  onMouseEnterMenuItem,
  onMouseLeaveNavigation,
  onMouseLeaveAccount,
  onClickNavigation,
  onToggleSearch,
  onClickStaticLink,
  onPageScroll,
  searchTerm,
}) {
  const { isDesktop, isTablet } = useViewportSize()

  const [navTimer, setNavTimer] = useState()
  const [headerBreakpoint, setHeaderBreakpoint] = useState(60)
  const [isHovered, setIsHovered] = useState(false)
  const [showSearchHeader, setShowSearchHeader] = useState(false)
  const [offset, setOffset] = useState()

  function handleMouseEnterOrLeave(hovered) {
    setIsHovered(hovered)
  }

  const handleScroll = useCallback(() => {
    const isMinified = !isTablet && getScrollTop() > headerBreakpoint + globalMessageHeight
    if (isMinified != isHeaderMinified) {
      onPageScroll(isMinified)
    }

    const newOffset = getScrollTop()
    const isBelowHeaderBreakpoint = newOffset > 150
    const isScrollingDown = newOffset > offset
    const newShowSearchHeader = isTablet && isBelowHeaderBreakpoint && isScrollingDown

    setOffset(newOffset)
    setShowSearchHeader(newShowSearchHeader)
  }, [globalMessageHeight, headerBreakpoint, isHeaderMinified, isTablet, offset, onPageScroll])

  useEffect(() => {
    setHeaderBreakpoint(cssEnvVars["--desktopMinifiedHeaderBreakpoint"])
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [handleScroll])

  const displayTransparent = !isHeaderMinified && !isHovered && isTransparentOnLoad && !searchTerm

  const cartIconWithCount = (
    <div className={styles.cartIcon}>
      <CartIcon />
      {!!cartCount && (
        <span aria-label={`${cartCount} Items in Your Cart`} className={styles.cartQtyNumber}>
          {cartCount}
        </span>
      )}
    </div>
  )

  // if scrolling down show search message, otherwise show Bonobos logo
  const titleContent = showSearchHeader ? (
    <div className={styles.logoIcon}>
      {
        // TODO: Update this component so that we don't need to ignore the eslint rules
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div aria-label="Homepage" onClick={onToggleSearch}>
          Search Bonobos...
        </div>
      }
    </div>
  ) : (
    <Link
      href="/"
      aria-label="Homepage"
      onClick={() => onClickStaticLink("/", "0", "Home", "top nav")}
    >
      <div className={styles.logoIcon}>
        <Logo />
      </div>
    </Link>
  )

  return (
    <div
      className={classNames(
        "header-component",
        styles.component,
        (isTablet || isHeaderMinified) && styles.sticky,
        isHeaderMinified && styles.minified,
        !isTablet && [
          isTransparentOnLoad ? styles.transparentOnLoad : styles.solidOnLoad,
          (!isTransparentOnLoad || isHovered || searchTerm) && styles.whiteBackground,
          displayTransparent && isTextLight && styles.light,
        ]
      )}
      // Bump the header down if the global message is visible
      style={
        (!isHeaderMinified &&
          isTransparentOnLoad &&
          !isTablet && { top: `${globalMessageHeight}px` }) ||
        null
      }
      onMouseEnter={() => handleMouseEnterOrLeave(true)}
      onMouseLeave={() => handleMouseEnterOrLeave(false)}
    >
      <div className={classNames(styles.curtain, isDesktopNavOpen ? styles.open : styles.closed)} />
      <header>
        <div className={styles.topPortion}>
          <div className={styles.navButtonContainer}>
            <div className={styles.navButtonContainerWrapper}>
              <Button aria-label="Open Navigation" layout="icon-button" onClick={onClickNavigation}>
                <HamburgerIcon />
              </Button>
              <Button aria-label="Toggle Search" layout="icon-button" onClick={onToggleSearch}>
                <SearchIcon key="search-nav-icon" className={styles.searchIcon} />
              </Button>
            </div>
          </div>

          {!isHeaderMinified && (
            <div className={styles.guideShopLink}>
              <Link
                href="/guideshop"
                className="default-link"
                onClick={() => onClickStaticLink("/guideshop", "0", "Visit Us", "top nav")}
              >
                Find a Location
              </Link>
              <Link href="/fitting-room" className={styles.findYourFitLink}>
                Find Your Fit
              </Link>
            </div>
          )}

          <div className={styles.logoContainer}>{titleContent}</div>

          <div className={styles.accountContainer}>
            <div className={styles.accountContainerWrapper}>
              {(isDesktop || (!isTablet && !isHeaderMinified)) && (
                <div className={classNames(styles.searchContainer, "stickyAutosuggest")}>
                  <SearchContainer />
                </div>
              )}

              {!isHeaderMinified && (
                <Link
                  href="/promotional-offers"
                  className="default-link"
                  onClick={() =>
                    onClickStaticLink("/promotional-offers", "0", PROMO_PAGE_LINK_TEXT, "top nav")
                  }
                >
                  <div className={styles.staticLinkContainer}>{PROMO_PAGE_LINK_TEXT}</div>
                </Link>
              )}

              <AccountContent
                firstName={firstName}
                isLoggedIn={isLoggedIn}
                isAccountDropdownOpen={isAccountDropdownOpen}
                onClickLogout={onClickLogout}
                onMouseEnterAccount={onMouseEnterAccount}
                onMouseLeaveAccount={onMouseLeaveAccount}
                onClickAccount={onClickAccount}
                isTablet={isTablet}
              />
              <Link
                href={"/cart"}
                className={styles.cartLink}
                aria-label="Open Your Shopping Cart"
                onClick={onClickCart}
              >
                {/* TODO: remove the aria-label on this div and update e2e test correspondingly */}
                <div className={styles.cartBtn} aria-label="Open Your Shopping Cart">
                  {cartIconWithCount}
                </div>
              </Link>
            </div>
          </div>
        </div>
        {!isTablet && contentfulNavItems && (
          <div className={classNames([styles.navigationContainer])}>
            <div
              className={classNames(styles.navigationWrapper)}
              onMouseLeave={onMouseLeaveNavigation}
            >
              {contentfulNavItems &&
                contentfulNavItems.map((navItem, index) => (
                  <HeaderV2MenuItem
                    active={activeNav === getField(navItem, "fullDisplayName")}
                    displayText={getField(navItem, "fullDisplayName")}
                    key={`L0-navItem-${index}`}
                    onClick={() =>
                      onClickMenuItem(
                        getField(navItem, "fullDisplayName"),
                        getField(navItem, "url")
                      )
                    }
                    onMouseEnter={() =>
                      setNavTimer(
                        setTimeout(
                          () => {
                            onMouseEnterMenuItem(getField(navItem, "fullDisplayName"))
                          },
                          activeNav ? NAV_DISPLAY_DELAY : NAV_OPEN_DELAY
                        )
                      )
                    }
                    onMouseLeave={() => {
                      clearTimeout(navTimer)
                      setNavTimer(null)
                    }}
                    path={getField(navItem, "url")}
                    title={getField(navItem, "fullDisplayName")}
                  />
                ))}
              <div
                className={classNames(
                  styles.desktopNavigationContainer,
                  activeNav !== "" ? styles.open : null
                )}
              >
                {isDesktopNavOpen && <DesktopNavigationContainer />}
              </div>
            </div>
          </div>
        )}
        {isTablet && (
          <div className={styles.loadingBg}>
            <LoadingBar
              maxProgress={LOADING_BAR_MAX_PROGRESS}
              className={styles.loadingBar}
              showFastActions
            />
          </div>
        )}
      </header>
    </div>
  )
}
HeaderV2.propTypes = {
  activeNav: PropTypes.string,
  cartCount: PropTypes.number,
  firstName: PropTypes.string,
  globalMessageHeight: PropTypes.number,
  isLoggedIn: PropTypes.bool,
  isAccountDropdownOpen: PropTypes.bool,
  isDesktopNavOpen: PropTypes.bool,
  isHeaderMinified: PropTypes.bool,
  isTextLight: PropTypes.bool,
  isTransparentOnLoad: PropTypes.bool,
  contentfulNavItems: ImmutablePropTypes.list,
  onMouseEnterAccount: PropTypes.func,
  onClickAccount: PropTypes.func,
  onClickCart: PropTypes.func,
  onClickMenuItem: PropTypes.func,
  onClickLogout: PropTypes.func,
  onMouseEnterMenuItem: PropTypes.func,
  onMouseLeaveNavigation: PropTypes.func,
  onMouseLeaveAccount: PropTypes.func,
  onClickNavigation: PropTypes.func,
  onToggleSearch: PropTypes.func,
  onClickStaticLink: PropTypes.func,
  onPageScroll: PropTypes.func,
  searchTerm: PropTypes.string,
}

HeaderV2.defaultProps = {
  activeNav: "",
  cartCount: 0,
  firstName: "",
  globalMessageHeight: 0,
  isAccountDropdownOpen: false,
  isDesktopNavOpen: false,
  isHeaderMinified: false,
  isLoggedIn: false,
  onMouseEnterAccount: () => {},
  onClickAccount: () => {},
  onClickCart: () => {},
  onClickMenuItem: () => {},
  onClickLogout: () => {},
  onMouseLeaveAccount: () => {},
  onMouseLeaveNavigation: () => {},
  onClickNavigation: () => {},
  onToggleSearch: () => {},
  onClickStaticLink: () => {},
  onPageScroll: () => {},
}

export default HeaderV2
