import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { FilterIcons } from "highline/components/icons"

import styles from "highline/styles/components/accordion.module.css"

const ICON_SET = ["default", "arrows"]
const FONT_WEIGHT = ["", "fontWeight900"]
const PADDING = ["", "spacingXs", "spacingSm", "spacingMd", "spacingLg", "spacingXl", "spacingHuge"]

import {
  Accordion as AccessibleAccordion,
  AccordionItem as AccessibleAccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion"

const Accordion = ({
  accordionItems,
  allowMultipleExpanded,
  allowZeroExpanded,
  headerFontWeight,
  identifier,
  iconSet,
  layout,
  onChange,
  padding,
  preExpandedItemPositions,
}) => {
  const Icons = iconSet && ICON_SET.includes(iconSet) ? FilterIcons[iconSet] : ICON_SET[0]
  const ExpandedIcon = Icons.Expanded
  const CollapsedIcon = Icons.Collapsed

  const getAccordionItemsList = (accordionItems, identifier) => {
    return accordionItems.map((item, index) => {
      return (
        <AccessibleAccordionItem
          key={`accordion_item-${index}`}
          className={classNames(
            styles.accordion__item,
            PADDING.includes(padding) && styles[padding]
          )}
          uuid={index}
        >
          <AccordionItemState>
            {({ expanded }) => {
              return (
                <>
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className={classNames(
                        `${identifier}-${index}`,
                        styles.accordion__button,
                        FONT_WEIGHT.includes(headerFontWeight) && styles[headerFontWeight]
                      )}
                    >
                      {item.header}
                      {expanded ? (
                        <ExpandedIcon className={styles.expandIcon} />
                      ) : (
                        <CollapsedIcon className={styles.expandIcon} />
                      )}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel
                    className={classNames(styles.accordion__panel, !expanded && styles["hidden"])}
                  >
                    {item.content}
                  </AccordionItemPanel>
                </>
              )
            }}
          </AccordionItemState>
        </AccessibleAccordionItem>
      )
    })
  }

  const accordionItemsList = getAccordionItemsList(accordionItems, identifier)

  return (
    <div className={styles.wordBreak}>
      <AccessibleAccordion
        className={(styles.accordion, styles[layout])}
        allowMultipleExpanded={allowMultipleExpanded}
        allowZeroExpanded={allowZeroExpanded}
        preExpanded={preExpandedItemPositions}
        onChange={onChange}
      >
        {accordionItemsList}
      </AccessibleAccordion>
    </div>
  )
}

Accordion.propTypes = {
  accordionItems: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.object,
      header: PropTypes.object,
    })
  ),
  allowMultipleExpanded: PropTypes.bool,
  allowZeroExpanded: PropTypes.bool,
  headerFontWeight: PropTypes.oneOf(FONT_WEIGHT),
  iconSet: PropTypes.oneOf(ICON_SET),
  identifier: PropTypes.string,
  layout: PropTypes.oneOf(["defaultLayout", "largeLayout", "plpSideNav"]),
  onChange: PropTypes.func,
  padding: PropTypes.oneOf(PADDING),
  preExpandedItemPositions: PropTypes.array,
}

Accordion.defaultProps = {
  accordionItems: [],
  allowMultipleExpanded: false,
  allowZeroExpanded: true,
  headerFontWeight: FONT_WEIGHT[0],
  identifier: "accordion",
  iconSet: ICON_SET[0],
  layout: "defaultLayout",
  onChange: () => {},
  padding: "",
  preExpandedItemPositions: [],
}

export default Accordion
