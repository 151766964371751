export const useSelectAuthenticationToken = (state) => state.getIn(["auth", "authenticationToken"])

export const useSelectUserEmail = (state) => state.getIn(["auth", "email"]) ?? ""

export const useSelectIsLoggedIn = (state) => state.getIn(["auth", "isLoggedIn"])

export const useSelectIsLoading = (state) => state.getIn(["auth", "isLoading"])

export const useSelectError = (state) => state.getIn(["auth", "error"])

export const useSelectUseSignInForm = (state) => state.getIn(["auth", "useSignInForm"])

export const useSelectFirstName = (state) => state.getIn(["auth", "firstName"])

export const useSelectLastName = (state) => state.getIn(["auth", "lastName"])

export const useSelectValidEmail = (state) => state.getIn(["auth", "validEmail"])

export const useSelectCcpaEmail = (state) => state.getIn(["auth", "encryptedValue", "emailId"])

export const useSelectHideForms = (state) => state.getIn(["auth", "hideForms"])

export const useSelectShouldShowResetPasswordPrompts = (state) =>
  state.getIn(["auth", "shouldShowResetPasswordPrompts"])

export const useSelectResetPasswordSuccess = (state) =>
  state.getIn(["auth", "resetPasswordSuccess"])

export const useSelectIsBonobosUser = (state) => state.getIn(["auth", "isBonobosUser"])
