import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import HorizontalScrollArea from "highline/components/horizontal_scroll_area"
import styles from "highline/styles/components/swiper.module.css"

const Swiper = ({ children }) => (
  <div className={classNames("component", "swiper-component", styles.component)}>
    <HorizontalScrollArea layout="productTiles">{children}</HorizontalScrollArea>
  </div>
)

Swiper.propTypes = {
  children: PropTypes.array.isRequired,
}

export default Swiper
