import Rollbar from "highline/utils/rollbar"
import getConfig from "highline/config/application"
import { getRecaptcha } from "highline/globals"
const { googleReCaptchaSiteKey } = getConfig()

const maxNumberOfAttempts = 2

const recaptchaGuard =
  (action, cb) =>
  async (...args) => {
    let recaptchaToken

    try {
      const recaptcha = getRecaptcha()
      let attempts = 0

      while (attempts < maxNumberOfAttempts && !recaptchaToken) {
        recaptchaToken = await recaptcha.execute(googleReCaptchaSiteKey, {
          action,
        })

        attempts++
      }
    } catch (error) {
      Rollbar.error(error)
    }

    return cb(...args, recaptchaToken)
  }

export default recaptchaGuard
