import insane from "insane" // sanitize html
import xss from "xss"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { fromJS } from "immutable"
import { renderContentfulComponent } from "highline/utils/contentful/component_helper"

const allowAttributes = {
  a: ["href", "style", "target", "title"],
  div: ["style"],
  h1: ["style"],
  h2: ["style"],
  h3: ["style"],
  h4: ["style"],
  h5: ["style"],
  h6: ["style"],
  iframe: ["frameborder", "src", "title", "id", "width", "height"],
  img: ["src", "style", "alt", "height", "width"],
  p: ["style"],
  span: ["style"],
  u: [],
}

export const htmlSanitizer = (callbackFn) => {
  const renderEmbeddedEntry = (node) => (
    <span className="embedded">
      {renderContentfulComponent(fromJS(node.data.target), callbackFn)}
    </span>
  )
  const xssOptions = {
    stripIgnoreTag: false,
    whiteList: allowAttributes,
    stripIgnoreTagBody: ["script"],
  }
  const options = {
    renderMark: {
      [MARKS.CODE]: (code) => (
        <div
          style={{ display: "inline" }}
          dangerouslySetInnerHTML={{
            __html: insane(xss(code, xssOptions), {
              allowedAttributes: allowAttributes,
              allowedTags: [
                "a",
                "blockquote",
                "br",
                "div",
                "h1",
                "h2",
                "h3",
                "h4",
                "h5",
                "h6",
                "hr",
                "i",
                "iframe",
                "img",
                "li",
                "ol",
                "p",
                "span",
                "strike",
                "strong",
                "u",
                "ul",
              ],
              displayName: "Inline code",
            }),
          }}
        ></div>
      ),
    },
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ENTRY]: renderEmbeddedEntry,
      [INLINES.EMBEDDED_ENTRY]: renderEmbeddedEntry,
    },
  }

  return options
}
