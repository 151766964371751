import React from "react"
import PropTypes from "prop-types"
import NextImages from "highline/components/next_images"
import { PRODUCT_TILE_STANDARD_IMAGE_WIDTH } from "highline/utils/image_width_helper"
import { environmentVariables as cssEnvVars } from "highline/css-env-variables"

import styles from "highline/styles/components/category/product_tile_image.module.css"

const ProductTileImage = ({
  name,
  primaryImageUrl,
  secondaryImageUrl,
  showAlternateImage,
  priority,
  placeholder,
  isMobile,
}) => {
  let imgSrc = primaryImageUrl || ""
  if (showAlternateImage && secondaryImageUrl) {
    imgSrc = secondaryImageUrl
  }

  const ASPECT_RATIO = 640 / 960
  const smartphoneProductTileImageWidth = cssEnvVars["--productTileMobileWidth"]
  const desktopProductTileImageWidth = cssEnvVars["--productTileTabletAndDesktopWidth"]
  const percentWidth = isMobile
    ? smartphoneProductTileImageWidth / 100
    : desktopProductTileImageWidth / 100

  return (
    <div className={styles.image}>
      <NextImages
        src={imgSrc}
        alt={name}
        ariaLabel={name}
        wrapperClassNames={[styles.portraitStoryImage, styles.storyImageWrapper]}
        width={PRODUCT_TILE_STANDARD_IMAGE_WIDTH}
        height={PRODUCT_TILE_STANDARD_IMAGE_WIDTH / ASPECT_RATIO}
        priority={priority}
        placeholder={placeholder}
        objectFit="cover"
        percentageWidth={percentWidth}
      />
    </div>
  )
}

ProductTileImage.propTypes = {
  name: PropTypes.string,
  primaryImageUrl: PropTypes.string,
  placeholder: PropTypes.string,
  priority: PropTypes.bool,
  secondaryImageUrl: PropTypes.string,
  showAlternateImage: PropTypes.bool,
  isMobile: PropTypes.bool,
}

ProductTileImage.defaultProps = {
  showAlternateImage: false,
  priority: false,
}

const MemoizedProductTileImage = React.memo(ProductTileImage)
MemoizedProductTileImage.displayName = "ProductTileImage"

export default MemoizedProductTileImage
