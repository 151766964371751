import { useState, useEffect } from "react"

export function useIsFirstRender() {
  const [isFirstRender, setIsFirstRender] = useState(false)

  useEffect(() => {
    setIsFirstRender(true)
  }, [])

  return isFirstRender
}
