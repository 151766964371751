import React from "react"
import PropTypes from "prop-types"
import ReactLazyLoad from "react-lazyload"
import styles from "highline/styles/components/lazy_load.module.css"

const LazyLoad = ({ disabled, children, ...other }) => {
  if (disabled) {
    return children
  }

  const customProps = Object.assign({}, LazyLoad.defaultProps, other)

  return <ReactLazyLoad {...customProps}>{children}</ReactLazyLoad>
}

LazyLoad.propTypes = {
  height: PropTypes.number,
  offset: PropTypes.number,
  once: PropTypes.bool,
  resize: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

LazyLoad.defaultProps = {
  disabled: false,
  height: 0,
  offset: 0,
  resize: true,
  once: true,
  className: styles.component,
}

export default LazyLoad
