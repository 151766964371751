import { getPromoDiscount } from "highline/redux/helpers/category_helper"
import {
  getFooterLinks,
  getFooterSubscriptionMessage,
} from "highline/utils/contentful/contentful_helper"

export function useSelectPromoDiscount(state) {
  return getPromoDiscount(state.getIn(["activePromotion", "promo"]))
}

export const useSelectFooterLinks = (state) => {
  const globals = state.getIn(["contentful", "globals"])
  return getFooterLinks(globals)
}

export const useSelectFooterSubscriptionMessage = (state) => {
  const data = getFooterSubscriptionMessage(state.getIn(["contentful", "globals"]))

  if (!data) {
    return {
      title: "15% Off Your First Order",
      description: "Want exclusive offers and first access to products? Sign up for email alerts.",
    }
  }

  return data
}

export const useSelectContentfulData = (state, page) => state.getIn(["contentful", "pages", page])

export const useSelectPromoExclusionsStatus = (state) => state.getIn(["promotion", "status"])

export const useSelectPromoExclusionsList = (state) => state.getIn(["promotion", "exclusions"])
