import { useSelectContentfulData } from "highline/selectors/contentful/contentful_selectors"

export const useSelectCartNumber = (state) => state.getIn(["cart", "number"])

export const useSelectAdyenMerchantSignature = (state) =>
  state.getIn(["cart", "adyenMerchantSignature"])

export const useSelectBundleDiscountTotal = (state) => state.getIn(["cart", "bundleDiscountTotal"])

export const useSelectBundleDiscountTotalNumeric = (state) =>
  state.getIn(["cart", "bundleDiscountTotalNumeric"])

export const useSelectCartContentfulData = (state) => useSelectContentfulData(state, "/cart")

export const useSelectEstimatedStoreCredit = (state) =>
  state.getIn(["cart", "estimatedStoreCredit"])

export const useSelectEstimatedStoreCreditNumeric = (state) =>
  state.getIn(["cart", "estimatedStoreCreditNumeric"])

export const useSelectEstimatedTotal = (state) => state.getIn(["cart", "estimatedTotal"])

export const useSelectGiftNote = (state) => state.getIn(["cart", "giftNote"])

export const useSelectIsGift = (state) => state.getIn(["cart", "isGift"])

export const useSelectIsLoading = (state) => state.getIn(["cart", "isLoading"])

export const useSelectIsNavigatingToCheckout = (state) =>
  state.getIn(["cart", "isNavigatingToCheckout"])

export const useSelectIsPromoFieldOpen = (state) => state.getIn(["cart", "isPromoFieldOpen"])

export const useSelectIsReady = (state) => state.getIn(["cart", "isReady"])

export const useSelectLineItems = (state) => state.getIn(["cart", "lineItems"])

export const useSelectMarkdown = (state) => state.getIn(["cart", "markdown"])

export const useSelectNewLineItemLoading = (state) => state.getIn(["cart", "newLineItemLoading"])

export const useSelectPaypalInputs = (state) => state.getIn(["cart", "paypalInputs"])

export const useSelectPromo = (state) => state.getIn(["cart", "promo"])

export const useSelectPromoCodeDetails = (state) => state.getIn(["cart", "promoCodeDetails"])

export const useSelectPromoError = (state) => state.getIn(["cart", "promoError"])

export const useSelectSubtotal = (state) => state.getIn(["cart", "subtotal"])

export const useSelectSuggestedItem = (state) => state.getIn(["cart", "suggestedItem"])
