import React from "react"
import PropTypes from "prop-types"
import Video from "highline/components/video"
import StreamingVideo from "highline/components/streaming_video"

const VideoComponentSelector = ({ isStreaming, ...otherProps }) =>
  isStreaming ? <StreamingVideo {...otherProps} /> : <Video {...otherProps} />

VideoComponentSelector.propTypes = {
  isStreaming: PropTypes.bool,
}

VideoComponentSelector.defaultProps = {
  isStreaming: false,
}

export default VideoComponentSelector
