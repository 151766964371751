import React, { useEffect } from "react"
import classNames from "classnames"
import ImmutablePropTypes from "react-immutable-proptypes"
import { Map, toJS } from "immutable"
import PropTypes from "prop-types"
import Link from "highline/components/secure_link"
import { getField } from "highline/utils/contentful/contentful_helper"

import styles from "highline/styles/components/contentful/contentful_articles_list.module.css"

const ArticlesList = ({ articlesComponent }) => {
  const articles = getField(articlesComponent, "articles")

  return (
    <div
      className={classNames("component", "contentful-articles-list-component", styles.articlesList)}
    >
      <h1 className={styles.articlesTitle}>{getField(articlesComponent, "title")}</h1>

      <div className={styles.articleTileContainer}>
        {articles.size > 0 &&
          articles.map((articleTile, index) => (
            <Link
              href={getField(articleTile, "url")}
              key={`article-list-${getField(articleTile, "displayTitle")}-${index}`}
              className={styles.articleTile}
            >
              <>
                <div
                  className={styles.articleImage}
                  style={{
                    backgroundImage: `url(${getField(articleTile, "blogPostImage")
                      ?.get("fields")
                      ?.get("file")
                      ?.get("url")})`,
                  }}
                />
                <h2 className={styles.articleCategory}>{getField(articleTile, "category")}</h2>
                <h3 className={styles.articleTitle}>{getField(articleTile, "displayTitle")}</h3>
                <p className={styles.articleDescription}>{getField(articleTile, "description")} </p>
              </>
            </Link>
          ))}
      </div>
    </div>
  )
}

ArticlesList.propTypes = {
  articlesComponent: ImmutablePropTypes.map,
}

ArticlesList.defaultProps = {
  articlesComponent: Map(),
}

export default ArticlesList
