import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { fromJS, Map } from "immutable"
import classNames from "classnames"
import ImageCta from "highline/components/image_cta"
import { getClientSideLink } from "highline/utils/link"
import Link from "highline/components/secure_link"
import {
  getImgixUrl,
  getField,
  getContentfulId,
  getImageAspectRatio,
} from "highline/utils/contentful/contentful_helper"

import styles from "highline/styles/components/application/desktop_navigation.module.css"

const DesktopNavigation = ({
  categoryName,
  navImages,
  navColumns,
  navPath,
  onClickNavItem,
  onMouseEnterNavItem,
  onClickNavImageCta,
  onClickNavImageCtaTitle,
}) => {
  return (
    <div className={classNames("component", "desktop-navigation-component", styles.component)}>
      {navColumns &&
        navColumns.map((navColumn, index) => {
          const columnName = getField(navColumn, "displayName")
          const subNavItems = getField(navColumn, "navigationItems")
          const columnLink = getField(navColumn, "link")
            ? getClientSideLink(getField(navColumn, "link"))
            : null
          return (
            <div className={styles.navColumn} key={`${columnName || "columnHeader"}-${index}`}>
              {columnName && columnLink && (
                <Link as={columnLink.get("as")} href={columnLink.get("href")}>
                  <h3 className={classNames(styles.columnHeader, styles.linkText)}>{columnName}</h3>
                </Link>
              )}
              {columnName && !columnLink && <h3 className={styles.columnHeader}>{columnName}</h3>}
              <div className={classNames(styles.textWrapper)}>
                {subNavItems &&
                  subNavItems.map((subNavItem, index) => {
                    const navHeadingLink = getClientSideLink(getField(subNavItem, "url"))
                    const navHeadingLabel = getField(subNavItem, "displayName")
                    const navHeadingImageUrl = getField(subNavItem, "imageUrl")
                    const navBadge = getField(subNavItem, "badgeText")
                    if (!navHeadingLabel) {
                      return null
                    }

                    return (
                      <div
                        className={styles.navWrapper}
                        key={`L1-${columnName}-${index}-${navHeadingLabel}`}
                      >
                        <Link
                          as={navHeadingLink.get("as")}
                          href={navHeadingLink.get("href")}
                          className="navigation-link"
                          onClick={() => onClickNavItem(navHeadingLink, navHeadingLabel)}
                          onMouseEnter={() =>
                            onMouseEnterNavItem(navHeadingImageUrl, navHeadingLabel, navHeadingLink)
                          }
                        >
                          {navHeadingLabel}
                          {navBadge && <div className={styles.navBadge}>{navBadge}</div>}
                        </Link>
                      </div>
                    )
                  })}
              </div>
            </div>
          )
        })}
      <div className={classNames("image-area", styles.imageArea)}>
        {navImages &&
          navImages.map((navImage, index) => {
            if (!getField(navImage, "altText")) {
              return null
            }
            const link = getClientSideLink(getField(navImage, "targetUrl") || navPath)
            const title = getField(navImage, "title")
            const entryId = getContentfulId(navImage)
            const metadata = Map({ categoryName, title, entryId })
            const textExtended = getField(navImage, "textExtended")
            const aspectRatio = getImageAspectRatio(getField(navImage, "image"))
            return (
              <div className={styles.imageCtaWrapper} key={`Nav-image-${index}`}>
                <ImageCta
                  imageUrl={getImgixUrl(getField(navImage, "image"))}
                  link={link}
                  layout="large"
                  height={350}
                  width={aspectRatio * 350}
                  altText={getField(navImage, "altText")}
                  text={`Shop all ${categoryName}`}
                  onClickCTA={onClickNavImageCta}
                  metadata={metadata}
                />
                {
                  //First text line under image
                  textExtended && textExtended.get(0) && (
                    <Link
                      as={link.get("as")}
                      href={link.get("href")}
                      className={styles.imageTextFirst}
                      onClick={() => onClickNavImageCtaTitle(link, textExtended.get(0), metadata)}
                    >
                      {textExtended.get(0)}
                    </Link>
                  )
                }
                <div>
                  {
                    //Second text line under image
                    textExtended && textExtended.get(1) && (
                      <Link
                        as={link.get("as")}
                        href={link.get("href")}
                        className={styles.imageTextSecond}
                        onClick={() => onClickNavImageCtaTitle(link, textExtended.get(1), metadata)}
                      >
                        {textExtended.get(1)}
                      </Link>
                    )
                  }
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

DesktopNavigation.propTypes = {
  categoryName: PropTypes.string,
  navColumns: ImmutablePropTypes.list,
  navImages: PropTypes.object,
  navPath: PropTypes.string,
  onClickImage: PropTypes.func,
  onClickNavItem: PropTypes.func,
  onClickNavImageCta: PropTypes.func,
  onClickNavImageCtaTitle: PropTypes.func,
  onMouseEnterNavItem: PropTypes.func,
}

DesktopNavigation.defaultProps = {
  navImages: [],
  navItems: fromJS([]),
  onClickNavItem: () => {},
  onClickNavImageCta: () => {},
  onClickNavImageCtaTitle: () => {},
  onMouseEnterNavItem: () => {},
}

export default DesktopNavigation
