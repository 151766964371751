import React, { useEffect } from "react"
import classNames from "classnames"
import ImmutablePropTypes from "react-immutable-proptypes"
import { Map } from "immutable"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import PropTypes from "prop-types"
import { getField } from "highline/utils/contentful/contentful_helper"
import { getContentfulProducts } from "highline/utils/contentful/contentful_helper"
import { htmlSanitizer } from "highline/utils/contentful/htmlSanitizer"

import styles from "highline/styles/components/contentful/contentful_article.module.css"

const Article = ({ articleComponent, callbackFn, pageLoaded }) => {
  useEffect(() => {
    const productTiles = [...document.querySelectorAll(".contentful-product-tile")]
    const products = getContentfulProducts(productTiles)
    pageLoaded(products)
  }, [typeof window !== "undefined" && document.querySelectorAll(".contentful-product-tile")])

  return (
    <article
      className={classNames("component", "contentful-article-component", styles.contentSection)}
    >
      <h2 className={styles.category}>{getField(articleComponent, "category")}</h2>
      <h1 className={styles.title}>{getField(articleComponent, "displayTitle")}</h1>
      <h3 className={styles.description}>{getField(articleComponent, "description")}</h3>
      <h4 className={styles.mins}>{getField(articleComponent, "minutes")} MIN READ</h4>
      <h5 className={styles.date}>
        LAST MODIFIED: {articleComponent?.get("sys")?.get("updatedAt")?.split("T")[0]}
      </h5>

      <img
        src={getField(articleComponent, "blogPostImage")?.get("fields")?.get("file")?.get("url")}
        alt={getField(articleComponent, "blogPostImage")?.get("fields")?.get("title")}
        className={styles.articleImage}
      />

      <hr className={styles.separator} />

      {getField(articleComponent, "article") && (
        <div className={styles.richText}>
          {documentToReactComponents(
            getField(articleComponent, "article").toJS(),
            htmlSanitizer(callbackFn)
          )}
        </div>
      )}
    </article>
  )
}

Article.propTypes = {
  callbackFn: PropTypes.func,
  articleComponent: ImmutablePropTypes.map,
  pageLoaded: PropTypes.func,
}

Article.defaultProps = {
  callbackFn: () => {},
  articleComponent: Map(),
  pageLoaded: () => {},
}

export default Article
