import PropTypes from "prop-types"
import { Fragment } from "react"
import ImmutablePropTypes from "react-immutable-proptypes"
import { renderContentfulComponent } from "highline/utils/contentful/component_helper"
import ContentfulHorizontalScroll from "highline/components/contentful/contentful_horizontal_scroll"
import { useContentfulProductTileFilter } from "highline/hooks/use_contentful_product_tile_filter"

function ContentfulHorizontalScrollSelector({ content, callbackFn, ...rest }) {
  const components = useContentfulProductTileFilter(content)

  return (
    <ContentfulHorizontalScroll {...rest}>
      {components.map((component, subIndex) => (
        <Fragment key={subIndex}>
          {renderContentfulComponent(component, callbackFn, `horizontal-scroll-${subIndex}`)}
        </Fragment>
      ))}
    </ContentfulHorizontalScroll>
  )
}

ContentfulHorizontalScrollSelector.propTypes = {
  content: ImmutablePropTypes.list,
  callbackFn: PropTypes.func,
}

export default ContentfulHorizontalScrollSelector
