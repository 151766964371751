import React from "react"
import ImmutablePropTypes from "react-immutable-proptypes"
import { Map } from "immutable"
import {
  getStylingObject,
  getParentStylingObject,
  getContentBlockStyle,
} from "highline/utils/contentful/page_styling_helper"
import { getField } from "highline/utils/contentful/contentful_helper"
import PropTypes from "prop-types"
import styles from "highline/styles/components/contentful/contentful_format_component.module.css"
import { useViewportSize } from "highline/hooks/use_viewport_size"

function ContentfulFormatWrapper({ blockComponent, children }) {
  const { isSmartphone } = useViewportSize()

  const parentFormat = blockComponent ? getParentStylingObject(isSmartphone, blockComponent) : null
  const format = blockComponent ? getStylingObject(isSmartphone, blockComponent) : null
  const blockStyle = blockComponent ? getContentBlockStyle(blockComponent) : null

  return getField(blockComponent, "formatting") ? (
    <div className={styles[blockStyle]} style={parentFormat}>
      <div className="contentFormatWrapper" style={format}>
        {children}
      </div>
    </div>
  ) : (
    children
  )
}

ContentfulFormatWrapper.propTypes = {
  blockComponent: ImmutablePropTypes.map,
  children: PropTypes.node,
}

ContentfulFormatWrapper.defaultProps = {
  blockComponent: Map(),
}

export default ContentfulFormatWrapper
