import React from "react"
import PropTypes from "prop-types"
import Button from "highline/components/button"
import AlertTag from "highline/components/alert_tag"
import LazyLoad from "highline/components/lazy_load"
import classNames from "classnames"
import styles from "highline/styles/components/product_tile_images.module.css"

/* Lazyload height affects the lazyload placeholder. Ideally it should mimic the height of the image that
replaces it. Image height is dynamic and this value does not need to be that accurate. Theoretically, not
exactly mimicing the number could affect layout shift, but the lazyload offset ensures the lazyload swap
takes place offscreen. Therefore it does not count towards layout shift. Lazyload offset indicates how far
offscreen the component is before the lazyload swap.
*/
const PRODUCT_TILE_IMAGE_LAZYLOAD_HEIGHT = 250 // px - https://github.com/twobin/react-lazyload#height
const PRODUCT_TILE_IMAGE_LAZYLOAD_OFFSET = 250 // px - https://github.com/twobin/react-lazyload#offset

const ProductTileImages = ({
  ariaLabel,
  badge,
  countDetails,
  ctaText,
  savedItemCtaComponent,
  imageComponent,
  inStock,
  onCTAClick,
  showCTA,
  showAlternateProductImage,
  lazyLoad,
  layout,
}) => (
  <div
    className={classNames([
      styles.imageWrapper,
      showAlternateProductImage ? styles.showQuickShop : styles.hideQuickShop,
      styles[layout],
    ])}
  >
    {showCTA && inStock && (
      <Button
        ariaLabel={ariaLabel}
        className={styles.ctaButton}
        layout="alternate"
        align="inline"
        onClick={onCTAClick}
      >
        {ctaText}
      </Button>
    )}

    {!inStock && (
      <AlertTag className={styles.soldOut} layout="adaptive">
        Out Of Stock
      </AlertTag>
    )}
    {lazyLoad && (
      <LazyLoad
        height={PRODUCT_TILE_IMAGE_LAZYLOAD_HEIGHT}
        offset={PRODUCT_TILE_IMAGE_LAZYLOAD_OFFSET}
      >
        {imageComponent}
      </LazyLoad>
    )}

    {!lazyLoad && imageComponent}

    {savedItemCtaComponent && savedItemCtaComponent}

    {countDetails && <span className={styles.countDetails}>{countDetails}</span>}

    {badge}
  </div>
)

ProductTileImages.propTypes = {
  ariaLabel: PropTypes.string,
  badge: PropTypes.node,
  countDetails: PropTypes.string,
  ctaText: PropTypes.string,
  savedItemCtaComponent: PropTypes.node,
  imageComponent: PropTypes.node,
  inStock: PropTypes.bool,
  layout: PropTypes.oneOf(["defaultLayout", "accompanyingProductsLayout"]),
  lazyLoad: PropTypes.bool,
  onCTAClick: PropTypes.func,
  showCTA: PropTypes.bool,
  showAlternateProductImage: PropTypes.bool,
}

ProductTileImages.defaultProps = {
  badge: null,
  inStock: true,
  layout: "defaultLayout",
  lazyLoad: false,
  onCTAClick: () => {},
  showCTA: false,
  showAlternateProductImage: false,
}

export default ProductTileImages
