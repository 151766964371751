import ActionTypes from "highline/redux/action_types"

export const addToast = (toast) => ({
  toast,
  type: ActionTypes.TOAST_ADDED,
})

export const showToast = () => ({
  type: ActionTypes.TOAST_DISPLAYED,
})

export const dismissToast = (manualDismiss = false) => ({
  type: ActionTypes.TOAST_DISMISSED,
  manualDismiss,
})

export const toastRemoveCartItemRow = (sku) => ({
  type: ActionTypes.TOAST_REMOVE_CART_ITEM,
  sku,
})

export const toastSaveUrlWhereOpen = (url) => ({
  type: ActionTypes.TOAST_OPEN_SAVE_URL,
  url,
})
