import { useMemo } from "react"
import { useSelector } from "react-redux"
import { getField, getContentType } from "highline/utils/contentful/contentful_helper"

export function useContentfulProductTileFilter(content) {
  const itemsDetails = useSelector((state) => state.getIn(["contentful", "itemsDetails"]))
  const components = useMemo(() => {
    if (!content) {
      return []
    }

    if (!itemsDetails) {
      return content
    }

    return content.filter((component) => {
      if (getContentType(component) !== "productTile") {
        return true
      }

      const sku = getField(component, "sku")
      const color = getField(component, "color")?.toLowerCase()
      const productKey = `${sku}-${color}`
      const itemDetail = itemsDetails.get(productKey)

      if (!itemDetail) {
        return true
      }

      const inStock = itemDetail.get("inStock")
      const shouldShowSoldOut = getField(component, "shouldShowSoldOut") ?? true

      return inStock || shouldShowSoldOut
    })
  }, [content, itemsDetails])

  return components
}
