import Image from "next/legacy/image"
import classNames from "classnames"
import PropTypes from "prop-types"
import { normalizeUrl } from "highline/utils/url"
import styles from "highline/styles/components/next_images.module.css"

export const srcsetLoader = ({ src, quality, width, forcedWidth }) => {
  try {
    const url = new URL(normalizeUrl(src))
    const params = url.searchParams
    // Constants for best image compression performance
    const AUTO = "compress,format"
    const FM = "pjpg"
    const pWidth = Math.floor(
      ((forcedWidth && forcedWidth.toString()) || width.toString() || params.get("w")) * 0.75
    )

    // default param values if the existing src doesn't include one
    params.set("auto", AUTO)
    params.set("fit", params.get("fit") || "clip")
    params.set("cs", params.get("cs") || "srgb")
    params.set("w", pWidth)
    params.set("fm", FM)

    return url.href
  } catch (e) {
    return src
  }
}

export const getBlurredUrl = (src) => {
  return srcsetLoader({ src, forcedWidth: 16, quality: 1 })
}

const NextImages = ({
  alt,
  ariaLabel,
  height,
  imageClassNames,
  priority,
  src,
  width,
  objectFit,
  objectPosition,
  wrapperClassNames,
  percentageWidth,
  placeholder,
  quality,
  unoptimized,
}) => {
  const hasDimensions = width && height
  return (
    <span
      className={classNames(styles.nextImageWrapper, ...wrapperClassNames)}
      role="img"
      aria-label={ariaLabel}
    >
      <Image
        src={src}
        loader={srcsetLoader}
        alt={alt}
        className={classNames(
          styles.nextImage,
          hasDimensions ? undefined : styles.nextImageLayoutFill,
          ...imageClassNames
        )}
        width={hasDimensions ? width : undefined}
        height={hasDimensions ? height : undefined}
        priority={priority}
        layout={hasDimensions ? "responsive" : "fill"}
        placeholder={priority ? placeholder || "blur" : "empty"}
        blurDataURL={priority ? getBlurredUrl(src) : ""}
        objectFit={objectFit}
        objectPosition={objectPosition}
        quality={quality}
        unoptimized={unoptimized}
        sizes={`${100 * percentageWidth}vw`}
      />
    </span>
  )
}

NextImages.propTypes = {
  alt: PropTypes.string,
  ariaLabel: PropTypes.string,
  height: PropTypes.number,
  imageClassNames: PropTypes.array,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  onLoad: PropTypes.func,
  percentageWidth: PropTypes.number,
  placeholder: PropTypes.string,
  priority: PropTypes.bool,
  quality: PropTypes.number,
  ref: PropTypes.func,
  src: PropTypes.string,
  unoptimized: PropTypes.bool,
  width: PropTypes.number,
  wrapperClassNames: PropTypes.array,
}

NextImages.defaultProps = {
  wrapperClassNames: [],
  imageClassNames: [],
  objectFit: "initial",
  objectPosition: "initial",
  onLoad: () => {},
  percentageWidth: 1,
  quality: 75,
  ref: () => {},
  unoptimized: false,
}

export default NextImages
