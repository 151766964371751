import { connect } from "react-redux"
import Accordion from "highline/components/accordion"
import { contentfulAccordionChanged } from "highline/redux/actions/contentful_actions"

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (openAccordionHeader) =>
    dispatch(contentfulAccordionChanged(openAccordionHeader, ownProps)),
})

const ContentfulAccordionContainer = connect(() => null, mapDispatchToProps)(Accordion)

export default ContentfulAccordionContainer
