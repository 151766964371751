import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Manager, Reference, Popper } from "react-popper"
import TooltipDropdown from "highline/components/tooltip/tooltip_dropdown"

import styles from "highline/styles/components/tooltip/tooltip.module.css"

const Tooltip = ({
  children,
  className,
  dropdown,
  layout,
  offset,
  preventOverflow,
  placement,
  target,
  isOpen,
  onClose,
  strategy,
}) => {
  const buildModifiersArray = () => {
    const modifiers = []

    if (offset) {
      modifiers.push({
        name: "offset",
        options: {
          offset: [offset.x, offset.y],
        },
      })
    }

    if (preventOverflow) {
      modifiers.push({
        name: "preventOverflow",
        options: preventOverflow,
      })
    }

    return modifiers
  }

  return (
    <div
      className={classNames(
        "component",
        "tooltip-component",
        styles.component,
        styles[layout],
        className
      )}
    >
      <Manager>
        <Reference>
          {({ ref }) => (
            <span ref={ref} className={styles.target}>
              {target}
            </span>
          )}
        </Reference>
        {isOpen && !dropdown && (
          <Popper
            placement={placement}
            modifiers={buildModifiersArray()}
            className={styles.popper}
            strategy={strategy}
          >
            {({ ref, style, placement, arrowProps }) => (
              <span ref={ref} className={classNames("popperBg", styles.popperBg)} style={style}>
                {children}
                <button
                  aria-label="Close the Tooltip"
                  onClick={onClose}
                  className={styles.closeButton}
                >
                  Close
                </button>
                <span
                  ref={arrowProps.ref}
                  style={arrowProps.style}
                  className={classNames(styles.arrow, styles[placement])}
                />
              </span>
            )}
          </Popper>
        )}
        {isOpen && dropdown && (
          <TooltipDropdown style={styles.popperBg}>{children}</TooltipDropdown>
        )}
      </Manager>
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dropdown: PropTypes.bool,
  isOpen: PropTypes.bool,
  layout: PropTypes.oneOf(["default", "admin", "account", "sort"]),
  offset: PropTypes.object,
  preventOverflow: PropTypes.object,
  onClose: PropTypes.func,
  placement: PropTypes.string,
  target: PropTypes.node,
  strategy: PropTypes.oneOf(["fixed", "absolute"]),
}

Tooltip.defaultProps = {
  isOpen: false,
  dropdown: false,
  layout: "default",
  offset: {
    x: 0,
    y: 10,
  },
  preventOverflow: {
    boundariesElement: "viewport",
    enabled: true,
  },
  placement: "right",
  strategy: "absolute",
}

export default Tooltip
