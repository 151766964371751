export const USER_AUTH_STATUS_DISPLAY_RULES = {
  HIDE_FROM_AUTHENTICATED_USERS: "Hide from authenticated users",
  HIDE_FROM_UNAUTHENTICATED_USERS: "Hide from unauthenticated users",
  SHOW_TO_ALL_USERS: "Show to all users",
}

export const DEVICE_DISPLAY_RULES = {
  HIDE_ON_DESKTOP_AND_TABLET: "Hide on desktop and tablet",
  HIDE_ON_SMARTPHONE: "Hide on smartphone",
  SHOW_ON_ALL_DEVICES: "Show on all devices",
}

export const applyUserAuthStatusDisplayRule = (isUserLoggedIn, rule) => {
  const { HIDE_FROM_AUTHENTICATED_USERS, HIDE_FROM_UNAUTHENTICATED_USERS } =
    USER_AUTH_STATUS_DISPLAY_RULES
  switch (rule) {
    case HIDE_FROM_AUTHENTICATED_USERS:
      return !isUserLoggedIn
    case HIDE_FROM_UNAUTHENTICATED_USERS:
      return isUserLoggedIn
    default:
      return true
  }
}

export const applyDeviceDisplayRule = (isSmartphone, rule) => {
  const { HIDE_ON_DESKTOP_AND_TABLET, HIDE_ON_SMARTPHONE } = DEVICE_DISPLAY_RULES
  switch (rule) {
    case HIDE_ON_DESKTOP_AND_TABLET:
      return isSmartphone
    case HIDE_ON_SMARTPHONE:
      return !isSmartphone
    default:
      return true
  }
}
