/* eslint-disable react/jsx-no-target-blank */
import { useState } from "react"
import PropTypes from "prop-types"
import { fromJS } from "immutable"
import classNames from "classnames"
import Link from "highline/components/secure_link"
import EmailForm from "highline/components/application/email_form"
import LinkIcon from "highline/components/link_icon"
import { SocialIcons } from "highline/components/icons"
import SupportID from "highline/components/application/support_id"
import { useSelector, useDispatch } from "react-redux"
import { useSelectCartNumber } from "highline/selectors/cart/cart_selectors"
import {
  useSelectFooterLinks,
  useSelectFooterSubscriptionMessage,
} from "highline/selectors/contentful/contentful_selectors"
import * as TermsAndPrivacyApi from "highline/api/terms_and_privacy_api"
import * as UserLeadApi from "highline/api/user_lead_api"
import { emailCaptured } from "highline/redux/actions/email_list_actions"
import { footerLinkClicked } from "highline/redux/actions/footer_actions"
import { clientConfig } from "highline/commonjs/config"
import styles from "highline/styles/components/application/footer.module.css"

const socialLinks = fromJS([
  {
    icon: SocialIcons.Instagram,
    isExternal: true,
    name: "Instagram",
    url: "https://instagram.com/bonobos",
  },
  {
    icon: SocialIcons.Facebook,
    isExternal: true,
    name: "Facebook",
    url: "https://www.facebook.com/bonobos",
  },
  {
    icon: SocialIcons.Twitter,
    isExternal: true,
    name: "Twitter",
    url: "https://twitter.com/bonobos",
  },
  {
    icon: SocialIcons.Youtube,
    isExternal: true,
    name: "Youtube",
    url: "https://www.youtube.com/user/bonobos",
  },
])

const Footer = ({ showEmailForm }) => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const [error, setError] = useState({})

  const number = useSelector(useSelectCartNumber)
  const footerLinks = useSelector(useSelectFooterLinks)
  const footerSubscriptionMessage = useSelector(useSelectFooterSubscriptionMessage)

  const onEmailSubmit = async () => {
    const LEAD_SOURCE = "signup_footer"

    setIsLoading(true)

    try {
      await TermsAndPrivacyApi.accept(email)
      await UserLeadApi.save(email, LEAD_SOURCE)

      dispatch(emailCaptured("Footer", email, LEAD_SOURCE))
      setIsLoading(false)
      setSubscribed(true)
      setError({})
    } catch (error) {
      setIsLoading(false)
      setError({
        email: error.data.getIn(["errors", "userLead", 0], "Something went wrong"),
      })
    }
  }

  return (
    <div className={classNames("component", "footer-component", styles.component)} id="footer">
      <div className={styles.footerContents}>
        <div className={styles.social}>
          {showEmailForm && (
            <div className={styles.email}>
              <EmailForm
                description={footerSubscriptionMessage.description}
                disabled={isLoading}
                email={email}
                errorMessage={error.email}
                layout="light"
                onChange={(e) => setEmail(e.target.value)}
                onSubmit={onEmailSubmit}
                placeholder="Your email address"
                placement="footer"
                subscribed={subscribed}
                title={footerSubscriptionMessage.title}
              />
            </div>
          )}
          <div className={styles.socialIcons}>
            {socialLinks.map((socialLink) => (
              <LinkIcon
                aria-label={`Navigate to ${socialLink.get("url")}`}
                className={styles.socialIcon}
                key={socialLink.get("name")}
                svg={
                  socialLink.get(
                    "icon"
                  )() /* invoke function to get element babel-plugin-inline-react */
                }
                href={socialLink.get("url")}
                onClick={() =>
                  dispatch(footerLinkClicked(socialLink.get("url"), socialLink.get("name")))
                }
                rel="noopener"
                target="_blank"
              />
            ))}
          </div>
        </div>
        <div className={styles.links}>
          {footerLinks.map((linkRow, index) => (
            <div className={styles.linkRow} key={index}>
              {linkRow.map((link) => {
                const AnchorIcon = link.get("icon")

                if (link.get("name") === "Cookie Preferences") {
                  if (clientConfig.oneTrustCookie) {
                    // This line should be remove once in prod
                    return (
                      <button
                        className={classNames(
                          "ot-sdk-show-settings",
                          "default-link",
                          styles.cookiePreference
                        )}
                        aria-label={`Navigate to ${link.get("name")}`}
                        arial-role="tab"
                      >
                        {link.get("name")}
                      </button>
                    )
                  }
                  return null // This line should be remove once in prod
                }

                if (link.get("isExternal")) {
                  return (
                    <a
                      href={link.get("url")}
                      aria-label={`Navigate to ${link.get("name")}`}
                      className="default-link"
                      onClick={() => dispatch(footerLinkClicked(link.get("url"), link.get("name")))}
                      target={link.get("openInNewTab") ? "_blank" : "_self"}
                      rel={link.get("isExternal") ? "noopener noreferrer" : undefined}
                    >
                      {AnchorIcon ? <AnchorIcon /> : ""}
                      {link.get("name")}
                    </a>
                  )
                }

                return (
                  <Link
                    href={link.get("url")}
                    key={link.get("name")}
                    aria-label={`Navigate to ${link.get("name")}`}
                    className="default-link"
                    onClick={() => dispatch(footerLinkClicked(link.get("url"), link.get("name")))}
                    target={link.get("openInNewTab") ? "_blank" : "_self"}
                  >
                    {AnchorIcon ? <AnchorIcon /> : ""}
                    {link.get("name")}
                  </Link>
                )
              })}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.copyright}>
        <p>&copy;{new Date().getFullYear()} Express BNBS Fashion, LLC All Rights Reserved</p>
        {showEmailForm && <SupportID number={number} />}
      </div>
    </div>
  )
}

Footer.propTypes = {
  showEmailForm: PropTypes.bool,
}

Footer.defaultProps = {
  showEmailForm: true,
}

export default Footer
