import { List } from "immutable"

export function useSelectExternalId(state) {
  return state.getIn(["savedItems", "externalId"]) ?? ""
}

export function useSelectFirstName(state) {
  return state.getIn(["savedItems", "firstName"])
}

export function useSelectItems(state) {
  return state.getIn(["savedItems", "items"]) ?? List()
}

export function useSelectIsLoading(state) {
  return state.getIn(["savedItems", "isLoading"]) ?? true
}
