import { v4 as uuidv4 } from "uuid"
import * as CookieUtil from "highline/utils/cookies"
import getConfig from "highline/config/application"

const SOD_COOKIE_NAME = "SOD"

export const setSodCookie = () => {
  const { cookieDomain } = getConfig()

  const encodedCookieName = `${SOD_COOKIE_NAME}=true_${uuidv4()};Domain=${cookieDomain}`
  document.cookie = encodeURI(encodedCookieName)
}

export const getSodCookie = () => {
  const cookieId = CookieUtil.get(SOD_COOKIE_NAME)
  if (cookieId) {
    return cookieId.split("true_")[1]
  }
  return null
}

export const getGpcSodCookie = () => {
  const cookieId = CookieUtil.get(SOD_COOKIE_NAME)
  if (cookieId) {
    return cookieId.split("GPC_")[1]
  }
  return null
}
