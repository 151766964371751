import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import PropTypes from "prop-types"
import { List, Map } from "immutable"
import classNames from "classnames"
import Link from "highline/components/secure_link"
import Cta from "highline/components/cta"
import ProductTile from "highline/components/category/product_tile"
import DynamicGrid from "highline/components/dynamic_grid"
import LoadingCurtain from "highline/components/loading_curtain"
import { getDiscountedPrice } from "highline/redux/helpers/product_detail_helper"
import { generatePDPLink } from "highline/redux/helpers/category_helper"
import { shouldExcludeProgram } from "highline/utils/promo_auto_apply_helper"
import {
  savedItemsRemoveProductAsync,
  savedItemsFetchAsync,
  savedItemsPublicFetchAsync,
} from "highline/redux/actions/saved_items_actions"
import { savedItemClicked } from "highline/redux/actions/saved_items_actions"
import { productPreviewClicked } from "highline/redux/actions/category_actions"
import styles from "highline/styles/components/account/saved_items.module.css"
import { useSelectExternalId } from "highline/selectors/saved_items/saved_items_selectors"
import {
  useSelectFirstName,
  useSelectIsLoading,
  useSelectItems,
} from "highline/selectors/saved_items/saved_items_selectors"
import { useSelectPromoDiscount } from "highline/selectors/contentful/contentful_selectors"
import { useSelectPromotionExclusions } from "highline/selectors/promotion/promotion_selectors"

function SavedItems({ contentfulId, isPublicPage, isInline, title, wishlistId }) {
  const dispatch = useDispatch()
  const externalId = useSelector(useSelectExternalId)
  const firstName = useSelector(useSelectFirstName)
  const promoDiscount = useSelector(useSelectPromoDiscount)
  const items = useSelector(useSelectItems)
  const isLoading = useSelector(useSelectIsLoading)
  const promotionExclusions = useSelector(useSelectPromotionExclusions)

  const shouldHide = isInline && items.size < 1

  function handleQuickAddClick(item) {
    const slug = item.get("productSlug")
    const options = item.get("options")

    dispatch(productPreviewClicked(slug, options))
  }

  function handleSavedItemCtaClick(item) {
    dispatch(savedItemsRemoveProductAsync(item, "wishlist"))
  }

  function handleProductTileClick(item) {
    dispatch(savedItemClicked(item, contentfulId))
  }

  const onLoad = useCallback(() => {
    if (isPublicPage) {
      dispatch(savedItemsPublicFetchAsync(wishlistId))
    } else {
      dispatch(savedItemsFetchAsync(wishlistId))
    }
  }, [isPublicPage, wishlistId, dispatch])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  if (shouldHide) {
    return null
  }

  return (
    <div
      className={classNames(
        "component",
        "saved-items-component",
        styles.component,
        isLoading ? styles.isLoading : null,
        isPublicPage ? styles.publicComponent : null,
        isInline ? styles.inline : null
      )}
    >
      <LoadingCurtain show={isLoading} delay={0} />

      {!isPublicPage && externalId && !isInline && (
        <div className={styles.share}>
          <h2 className={styles.shareHeader}>Want to give a not-so-subtle hint?</h2>
          <p className={styles.tagline}>
            Share your list with friends and family by copying this link and sending via email or
            text.
          </p>
          <Link
            href="/saved-items/[id]"
            as={`/saved-items/${externalId}`}
            className={styles.sharedLink}
          >
            {`${window.location.origin}/saved-items/${externalId}`}
          </Link>
        </div>
      )}

      {isPublicPage && firstName && !isInline && (
        <h2 className={styles.publicUser}>{`${firstName}'s Saved Items`}</h2>
      )}

      {isInline && title && (
        <>
          <h2 className={styles.shareHeader}>{title}</h2>
          <hr />
        </>
      )}

      {!items.isEmpty() && (
        <DynamicGrid noWrap={isInline}>
          {items.map((item, index) => {
            const isGiftCard = !item.getIn(["options", "color"])
            const color = isGiftCard
              ? item.getIn(["options", "theme"])
              : item.getIn(["options", "color"])
            const selectedOptions = item.get("options").map((option) => List([option]))
            const inStock = item.get("inStock")
            const link = inStock
              ? generatePDPLink(item.get("productSlug"), color, false, isGiftCard, selectedOptions)
              : generatePDPLink(item.get("productSlug"), color, false, isGiftCard, Map())
            const numericFullPrice = parseInt(item.get("price").substring(1))
            const shouldApplySitewidePromo =
              promoDiscount &&
              !shouldExcludeProgram(
                item.get("productSlug"),
                item.getIn(["options", "color"]),
                promotionExclusions.toJS()
              )
            const promoPrice = shouldApplySitewidePromo
              ? `$${getDiscountedPrice(numericFullPrice, promoDiscount)}`
              : null

            return (
              <ProductTile
                subtitle={
                  isGiftCard ? item.getIn(["options", "theme"]) : item.getIn(["options", "color"])
                }
                promoPrice={promoPrice}
                ctaText="Quick Add"
                fullPrice={item.get("originalPrice")}
                saved
                primaryImageUrl={item.get("image")}
                inStock={
                  item.get("fullySpecified") ? inStock : true
                } /* NK - temporary fix. Items saved from Category Pages have an inStock value of null. API update required */
                key={`${item.get("name")}-${index}`}
                onCTAClick={() => {
                  handleQuickAddClick(item)
                }}
                onSavedItemCtaClick={() => {
                  handleSavedItemCtaClick(item)
                }}
                onProductTileClick={() => {
                  handleProductTileClick(item)
                }}
                link={link}
                name={item.get("productName")}
                price={item.get("price")}
                showCTA={!isGiftCard && inStock}
                showSavedItemCta={!isPublicPage}
                isInline={isInline}
                promotionExclusions={promotionExclusions}
              />
            )
          })}
        </DynamicGrid>
      )}

      {!isLoading && items.isEmpty() && (
        <div className={styles.buttonContainer}>
          <Cta href="/">Start Browsing</Cta>
        </div>
      )}
    </div>
  )
}

SavedItems.propTypes = {
  isPublicPage: PropTypes.bool,
  isInline: PropTypes.bool,
  title: PropTypes.string,
  wishlistId: PropTypes.string,
  contentfulId: PropTypes.string,
}

SavedItems.defaultProps = {
  isPublicPage: false,
  isInline: false,
}

export default SavedItems
