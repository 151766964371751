import Image from "next/legacy/image"
import classNames from "classnames"
import PropTypes from "prop-types"
import styles from "highline/styles/components/next_images_with_hover.module.css"
import React from "react"
import { srcsetLoader, getBlurredUrl } from "highline/components/next_images"

const NextImagesWithHover = ({
  alt,
  ariaLabel,
  height,
  imageClassNames,
  priority,
  src,
  width,
  objectFit,
  objectPosition,
  wrapperClassNames,
  percentageWidth,
  placeholder,
  quality,
  unoptimized,
  onHoverImageSrc,
}) => {
  const [showAlternateImage, setShowAlternateImage] = React.useState(false)
  const hasDimensions = width && height
  const imageStyles = !showAlternateImage ? styles.showImage : styles.hideImage
  const hoverImageStyles = showAlternateImage ? styles.showImage : styles.hideImage

  return (
    <span
      className={classNames(
        styles.nextImageWrapper,
        ...wrapperClassNames,
        onHoverImageSrc ? styles.hoverImageWrapperStyles : ""
      )}
      role="img"
      aria-label={ariaLabel}
      onMouseOver={() => {
        onHoverImageSrc && setShowAlternateImage(true)
      }}
      onFocus={() => {
        onHoverImageSrc && setShowAlternateImage(true)
      }}
      onMouseOut={() => {
        onHoverImageSrc && setShowAlternateImage(false)
      }}
      onBlur={() => {
        onHoverImageSrc && setShowAlternateImage(false)
      }}
    >
      <Image
        src={src}
        loader={srcsetLoader}
        alt={alt}
        className={classNames(
          styles.nextImage,
          hasDimensions ? undefined : styles.nextImageLayoutFill,
          imageStyles,
          ...imageClassNames
        )}
        width={hasDimensions ? width : undefined}
        height={hasDimensions ? height : undefined}
        priority={priority}
        layout={hasDimensions ? "responsive" : "fill"}
        placeholder={priority ? placeholder || "blur" : "empty"}
        blurDataURL={priority ? getBlurredUrl(src) : ""}
        objectFit={objectFit}
        objectPosition={objectPosition}
        quality={quality}
        unoptimized={unoptimized}
        sizes={`${100 * percentageWidth}vw`}
      />
      {onHoverImageSrc && (
        <Image
          src={onHoverImageSrc}
          loader={srcsetLoader}
          alt={alt}
          className={classNames(
            styles.nextImage,
            hasDimensions ? undefined : styles.nextImageLayoutFill,
            hoverImageStyles,
            ...imageClassNames
          )}
          width={hasDimensions ? width : undefined}
          height={hasDimensions ? height : undefined}
          priority={priority}
          layout={hasDimensions ? "responsive" : "fill"}
          placeholder={priority ? placeholder || "blur" : "empty"}
          blurDataURL={priority ? getBlurredUrl(src) : ""}
          objectFit={objectFit}
          objectPosition={objectPosition}
          quality={quality}
          unoptimized={unoptimized}
          sizes={`${100 * percentageWidth}vw`}
        />
      )}
    </span>
  )
}

NextImagesWithHover.propTypes = {
  alt: PropTypes.string,
  ariaLabel: PropTypes.string,
  height: PropTypes.number,
  imageClassNames: PropTypes.array,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  onHoverImageSrc: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  percentageWidth: PropTypes.number,
  placeholder: PropTypes.string,
  priority: PropTypes.bool,
  quality: PropTypes.number,
  ref: PropTypes.func,
  src: PropTypes.string.isRequired,
  unoptimized: PropTypes.bool,
  width: PropTypes.number,
  wrapperClassNames: PropTypes.array,
}

NextImagesWithHover.defaultProps = {
  wrapperClassNames: [],
  imageClassNames: [],
  objectFit: "initial",
  objectPosition: "initial",
  onHoverImageSrc: null,
  onLoad: () => {},
  percentageWidth: 1,
  quality: 75,
  ref: () => {},
  unoptimized: false,
}

export default NextImagesWithHover
