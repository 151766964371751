import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import styles from "highline/styles/components/dynamic_grid.module.css"

const DynamicGrid = ({ children, itemsPerRow, noWrap }) => {
  /*
   * We insert a spacer element which takes up the empty portion of the last row.
   * Paired with 'justify-content: space-between', this ensures items have
   * proper spacing depending on the number of items in a row.
   */
  const numItemsInLastRow = children.size % itemsPerRow
  const spacerMultiplier = itemsPerRow - numItemsInLastRow
  const spacerWidth = Math.floor(100 / itemsPerRow) - 1
  return (
    <div
      className={classNames("dynamic-grid-component", styles.component, noWrap && styles.noWrap)}
    >
      {children}
      <div style={{ content: "", width: `${spacerWidth * spacerMultiplier}%` }}></div>
    </div>
  )
}

DynamicGrid.propTypes = {
  children: PropTypes.node,
  itemsPerRow: PropTypes.number,
  noWrap: PropTypes.bool,
}

DynamicGrid.defaultProps = {
  itemsPerRow: 3,
  noWrap: false,
}

export default DynamicGrid
