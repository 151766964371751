import getConfig from "highline/config/application"
import ConstructorIOClient from "@constructor-io/constructorio-client-javascript"
import { toCamelizedImmutable } from "highline/utils/immutable_helper"
import {
  convertRecForConstructor,
  convertToRecommendationProducts,
} from "highline/utils/product_mapper_helper"
import { convertOrderItemsForConstructorTracking } from "highline/utils/constructor_helper"
import { detectTabletWidth } from "highline/utils/viewport"
import { fromJS } from "immutable"
import constructorBrowseGroupFixture from "highline/cypress/fixtures/constructor/browse_group.json"
import { isServer } from "highline/utils/client"
import Rollbar from "highline/utils/rollbar"

const {
  constructorKey,
  constructorPlpNumResultsPerPageMobile,
  constructorPlpNumResultsPerPageDesktop,
  constructorSearchNumResultsPerPageMobile,
  constructorSearchNumResultsPerPageDesktop,
  isFeatureMode,
  numPdpRecommendations,
  numZeroResultsRecommendations,
} = getConfig()

export const FilterOnSale = {
  FULL_PRICE_AND_MARKDOWNS: "Full Price and Markdowns",
  FULL_PRICE_ONLY: "Full Price Only",
  MARKDOWNS_ONLY: "Markdowns Only",
}

export const client = new ConstructorIOClient({
  apiKey: constructorKey || "mock-string",
  segments: ["ecomm"],
  sendTrackingEvents: true,
})

export const fetchSearchResult = async (searchTerm, params = {}) => {
  const isTablet = detectTabletWidth()
  params["resultsPerPage"] = isTablet
    ? constructorSearchNumResultsPerPageMobile
    : constructorSearchNumResultsPerPageDesktop
  try {
    const response = await client.search.getSearchResults(searchTerm, params)
    return toCamelizedImmutable(response)
  } catch (err) {
    Rollbar.error(`Constructor: Search Failed - ${err.toString()}`, err)
    throw fromJS({ err })
  }
}

export const fetchCategory = async (
  categoryId,
  params = {},
  initialPageNumber = 1,
  isCollection = false
) => {
  // Conditional to support server-side constructor call in cypress test to route "/shop/clothing"
  if (isFeatureMode && isServer) {
    return toCamelizedImmutable(constructorBrowseGroupFixture)
  }

  const filterName = isCollection ? "collection_id" : "group_id"

  const isTablet = detectTabletWidth()
  const pageSize = isTablet
    ? constructorPlpNumResultsPerPageMobile
    : constructorPlpNumResultsPerPageDesktop
  params["resultsPerPage"] = initialPageNumber * parseInt(pageSize)

  const response = await client.browse.getBrowseResults(filterName, categoryId.toString(), params)

  return toCamelizedImmutable(response)
}

export const inCartRecommendations = async (itemIds) => {
  return client.recommendations
    .getRecommendations(recommendationPods.inCartPod1, { itemIds, numResults: 1 })
    .then((data) => {
      const immutableData = toCamelizedImmutable(data)
      const massagedData = convertRecForConstructor(immutableData, recommendationPods.inCartPod1)
      return massagedData
    })
    .catch((err) => {
      Rollbar.error(`Constructor: In Cart Recommendation Fetch Failed - ${err.toString()}`, err)
    })
}

const pdpOrZeroNumResults = (itemId) =>
  itemId.length > 0 ? numPdpRecommendations : numZeroResultsRecommendations

const pdpOrZeroPod = (itemId) =>
  itemId.length > 0 ? recommendationPods.pdpPod1 : recommendationPods.zeroResultsPod1

export const productRecommendations = async (
  itemId = "",
  pod = "",
  numResults = 0,
  { filterOnSale = FilterOnSale.FULL_PRICE_AND_MARKDOWNS, categoryFilter = "" }
) => {
  const $numResults = !!numResults ? numResults : pdpOrZeroNumResults(itemId)
  const $podId = pod.length > 0 ? pod : pdpOrZeroPod(itemId)
  const filters = {}

  // If value is `Full Price and Markdowns` then fallback to default behavior
  if (filterOnSale !== FilterOnSale.FULL_PRICE_AND_MARKDOWNS) {
    filters.on_sale = filterOnSale === FilterOnSale.MARKDOWNS_ONLY ? "True" : "False"
  }

  if (Boolean(categoryFilter) && categoryFilter.trim().length > 0) {
    filters.group_id = categoryFilter
  }

  return client.recommendations
    .getRecommendations($podId, {
      itemIds: itemId,
      numResults: $numResults,
      filters,
    })
    .then((data) => {
      const immutableData = toCamelizedImmutable(data)
      const massagedData = convertToRecommendationProducts(
        immutableData.getIn(["response", "results"]),
        pod
      )
      return massagedData
    })
    .catch((err) => {
      Rollbar.error(`Constructor: Product Recommendation Fetch Failed - ${err.toString()}`, err)
    })
}

// https://constructor-io.github.io/constructorio-client-javascript/module-tracker.html#~trackPurchase
export const trackCustomerPurchase = (order) => {
  const items = convertOrderItemsForConstructorTracking(order.get("items"))
  const orderNumber = order.get("number")
  const revenue = parseFloat(order.get("totalNumeric"))

  client.tracker.trackPurchase({
    items,
    order_id: orderNumber,
    revenue,
  })
}

const recommendationPods = {
  homePagePod1: "home_page_1",
  homePagePod2: "home_page_2",
  inCartPod1: "cart_page_1", // As of 11/14/19 - this is Complementary Items
  pdpPod1: "item_page_1", // As of 11/14/19 - this is Alternative Items
  pdpPod2: "item_page_2", // As of 11/14/19 - this is Complementary Items
  zeroResultsPod1: "zero_results_1",
}
