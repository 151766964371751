import React from "react"
import PropTypes from "prop-types"
import Hls from "hls.js"
import Video from "highline/components/video"

class StreamingVideo extends React.PureComponent {
  static propTypes = {
    autoPlay: PropTypes.bool,
    controls: PropTypes.bool,
    videoURL: PropTypes.string.isRequired,
  }

  static defaultProps = {
    autoPlay: false,
    control: true,
    videoURL: "",
  }

  state = {
    hlsInstance: null,
  }

  componentDidUpdate() {
    const url = this.props.videoURL
    const video = this.player
    // get HLS instance from state, OR instantiate it if it doesnt exist yet
    let hls
    if (this.state.hlsInstance === null) {
      hls = new Hls()
    } else {
      hls = this.state.hlsInstance
    }
    // only try to start the video when we know the url has been provided
    if (url.length > 0) {
      hls.loadSource(url)
      hls.attachMedia(video)
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        this.props.autoPlay ? video.play() : null
      })
    }
    // save HLS instance back to state so it can be reused
    this.setState({ hlsInstance: hls })
  }

  render() {
    return (
      <Video
        controls={this.props.controls}
        videoRef={(player) => (this.player = player)}
        videoURL={this.props.videoURL}
        playsInline
        {...this.props}
      />
    )
  }
}

export default StreamingVideo
