import { Map, List } from "immutable"
import * as CategoryApi from "highline/api/category_api"
import * as ConstructorApi from "highline/api/constructor_api"
import ActionTypes from "highline/redux/action_types"
import { fitPreferencesUserSelectionsFetchAsync } from "highline/redux/actions/fit_preferences_actions"
import {
  massageConstructorCategoryResponse,
  buildFiltersForConstructor,
} from "highline/utils/product_mapper_helper"
import { getPromoDiscount, getCollectionMetaData } from "highline/redux/helpers/category_helper.js"
import { redirect } from "highline/utils/navigate"

export const categoryBackToTopClicked = () => ({
  type: ActionTypes.CATEGORY_BACK_TO_TOP_CLICKED,
})

export const categoryFetchFailed = (error) => ({
  type: ActionTypes.CATEGORY_FETCH_FAILED,
  error,
})

export const categoryFetchStarted = (slug) => ({
  type: ActionTypes.CATEGORY_FETCH_STARTED,
  slug,
})

export const categoryImageHovered = () => ({
  type: ActionTypes.CATEGORY_IMAGE_HOVERED,
})

export const categoryNavItemClick = (name, anchor) => ({
  type: ActionTypes.CATEGORY_NAV_ITEM_CLICK,
  name,
  anchor,
})

export const navPillItemClicked = (name, path) => ({
  type: ActionTypes.NAV_PILL_ITEM_CLICKED,
  name,
  path,
})

export const emptyCategoryFetched = (res) => ({
  type: ActionTypes.EMPTY_CATEGORY_FETCHED,
  res,
})

export const productPreviewClicked = (slug, selectedOptions) => ({
  type: ActionTypes.PRODUCT_PREVIEW_CLICKED,
  slug,
  selectedOptions,
})

export const categoryProductVariantActivated = (itemKey, index) => ({
  type: ActionTypes.CATEGORY_PRODUCT_VARIANT_ACTIVATED,
  itemKey,
  index,
  location: "category",
})

export const categoryProductVariantDeactivated = (itemKey) => ({
  type: ActionTypes.CATEGORY_PRODUCT_VARIANT_DEACTIVATED,
  itemKey,
  location: "category",
})

export const categoryProductVariantSelected = (itemKey, index) => ({
  type: ActionTypes.CATEGORY_PRODUCT_VARIANT_SELECTED,
  itemKey,
  index,
  location: "category",
})

export const categoryPromoTileClicked = (title, position, slug) => ({
  type: ActionTypes.CATEGORY_PROMO_TILE_CLICKED,
  title,
  position,
  slug,
})

export const categoryBreadcrumbClicked = (path) => ({
  type: ActionTypes.CATEGORY_BREADCRUMB_CLICKED,
  path,
})

export const narrativeClicked = () => ({
  type: ActionTypes.NARRATIVE_CLICKED,
})

export const categoryPLPFetchSucceeded = (data) => ({
  type: ActionTypes.CATEGORY_PLP_FETCH_SUCCEEDED,
  data,
})

export const categoryConstructorFetchMoreStarted = () => ({
  type: ActionTypes.CATEGORY_CONSTRUCTOR_FETCH_MORE_STARTED,
})

export const categoryConstructorFetchMoreSucceeded = (data) => ({
  type: ActionTypes.CATEGORY_CONSTRUCTOR_FETCH_MORE_SUCCEEDED,
  data,
})

export const categoryConstructorFetchMoreFailed = (err) => ({
  type: ActionTypes.CATEGORY_CONSTRUCTOR_FETCH_MORE_FAILED,
  err,
})

export const categoryChanged = () => ({
  type: ActionTypes.CATEGORY_CHANGED,
})

export const categoryLoaded = (hasQueryParams) => ({
  type: ActionTypes.CATEGORY_LOADED,
  hasQueryParams,
})

const productTileClicked = (product) => ({
  product,
  type: ActionTypes.PRODUCT_TILE_CLICKED,
})

export const onProductTileClicked = (slug) => async (dispatch, getState) => {
  const productDetails = getState().getIn(["category", "itemsDetails", slug])
  const productDetailsData = productDetails.get("data").toJS()
  const { isBundle, isNewColor, isNewProgram, colorName, finalSale } = productDetailsData
  const name = productDetails.get("value")
  const product = {
    color: colorName,
    isBundle,
    isFinalSale: finalSale,
    isNewColor,
    isNewProgram,
    name,
  }
  dispatch(productTileClicked(product))
}

export const categoryConstructorFetchMoreAsync = () => async (dispatch, getState) => {
  dispatch(categoryConstructorFetchMoreStarted())
  const exclusions = getState().getIn(["promotion", "exclusions"])
  const id = getState().getIn(["category", "categoryId"])
  const isCollection = getState().getIn(["category", "isCollection"])
  const page = getState().getIn(["category", "pageNumber"]) + 1
  const myFitEnabled = getState().getIn(["filters", "myFitEnabled"])
  let selectedFilters = getState().getIn(["filters", "selectedFilters"])
  const sortBy = getState().getIn(["sort", "currentSortOption", "name"])
  const sortOrder = getState().getIn(["sort", "currentSortOption", "sortOrder"])
  const path = getState().getIn(["category", "path"])
  const displayOnlyMarkdownSwatches = path && path.includes("/sale")
  const promoDiscount = getPromoDiscount(getState().getIn(["activePromotion", "promo"]))

  if (myFitEnabled) {
    getState().getIn(["fitPreferences", "myFitFilters"]).isEmpty() &&
      (await dispatch(fitPreferencesUserSelectionsFetchAsync()))
    selectedFilters = getState().getIn(["fitPreferences", "myFitFilters"])
  }

  try {
    const constructorResponse = await ConstructorApi.fetchCategory(
      id,
      {
        filters: buildFiltersForConstructor(selectedFilters),
        sortBy,
        sortOrder,
        page,
      },
      1,
      isCollection
    )
    const response = massageConstructorCategoryResponse(constructorResponse, selectedFilters, {
      displayOnlyMarkdownSwatches,
      promoDiscount,
      exclusions,
    })
    const currentItems = getState().getIn(["category", "items"])
    const currentItemsDetails = getState().getIn(["category", "itemsDetails"])
    const items = currentItems.concat(response.get("items"))
    const itemsDetails = currentItemsDetails.merge(response.get("itemsDetails"))
    dispatch(
      categoryConstructorFetchMoreSucceeded(
        response.merge({
          items,
          itemsDetails,
          hasNextPage: items.size < response.get("numResults"),
        })
      )
    )
  } catch (err) {
    dispatch(categoryConstructorFetchMoreFailed(err))
  }
}

// make api call for category items
export const categoryFetchAsync =
  (category, res, isServer, isCollection = false) =>
  async (dispatch, getState) => {
    let myFitEnabled = getState().getIn(["filters", "myFitEnabled"])
    let slug = getState().getIn(["category", "slug"])
    let pageNumber = getState().getIn(["category", "pageNumber"])
    const path = getState().getIn(["category", "path"]) || slug
    const displayOnlyMarkdownSwatches = path && path.includes("/sale")
    const isSameCategoryBeingRequested = slug === category

    if (category && typeof category === "string" && !isSameCategoryBeingRequested) {
      slug = category
    }

    if (category && !isSameCategoryBeingRequested) {
      dispatch(categoryChanged())
      pageNumber = 1
    }

    let selectedFilters = getState().getIn(["filters", "selectedFilters"])

    if (isServer) {
      myFitEnabled = false
      selectedFilters = Map()
    }

    dispatch(categoryFetchStarted(slug))

    const currentSortOption = getState().getIn(["sort", "currentSortOption", "name"])

    try {
      if (myFitEnabled) {
        await dispatch(fitPreferencesUserSelectionsFetchAsync())
        selectedFilters = getState().getIn(["fitPreferences", "myFitFilters"])
      }

      const promoDiscount = getPromoDiscount(getState().getIn(["activePromotion", "promo"]))
      const exclusions = getState().getIn(["promotion", "exclusions"])

      /**  Get the slug valio directly from the STORE 
         and don't use the slug variable declare above
      **/
      const categoryMetaData = await CategoryApi.fetchMetaData(slug)

      const categoryId = isCollection ? category : categoryMetaData.data.get("categoryId")

      const sortOrder = getState().getIn(["sort", "currentSortOption", "sortOrder"])
      const constructorResponse = await ConstructorApi.fetchCategory(
        categoryId,
        {
          filters: buildFiltersForConstructor(selectedFilters),
          sortBy: currentSortOption || "relevance",
          sortOrder: sortOrder || "descending",
        },
        pageNumber,
        isCollection
      )

      const metaData = isCollection
        ? getCollectionMetaData(constructorResponse)
        : categoryMetaData.data

      const data = massageConstructorCategoryResponse(constructorResponse, selectedFilters, {
        displayOnlyMarkdownSwatches,
        promoDiscount,
        exclusions,
      })
        .merge(metaData)
        .merge({ categoryId, initialPageNumber: pageNumber })
        .merge({ isCollection })

      const hasNextPage = data.get("items").size < data.get("numResults")
      const pageSize = pageNumber > 0 ? data.get("pageSize") / pageNumber : 0
      const dataWithPageAndCount = data.merge({
        hasNextPage,
        pageNumber,
        pageSize,
      })

      return dispatch(categoryPLPFetchSucceeded(dataWithPageAndCount))
    } catch (error) {
      if (error.status === 404 && !isServer) {
        const categoryArr = category.split("/")
        const searchTerm = categoryArr[categoryArr.length - 1]

        return redirect(`/search?term=${searchTerm}`)
      }

      const emptyCategory =
        !error.data ||
        error.data
          .getIn(["errors", "category"], List())
          .includes("Category does not contain any purchasable items.")

      return emptyCategory
        ? dispatch(emptyCategoryFetched(res))
        : dispatch(categoryFetchFailed(error))
    }
  }
