import ActionTypes from "highline/redux/action_types"

export const productRecommendationClicked = (slug, sku, color) => ({
  type: ActionTypes.PRODUCT_RECOMMENDATION_CLICKED,
  color,
  sku,
  slug,
})

export const productRecommendationsDisplayed = () => ({
  type: ActionTypes.PRODUCT_RECOMMENDATIONS_DISPLAYED,
})
