import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import Link from "highline/components/secure_link"
import { Map } from "immutable"
import NextImages from "highline/components/next_images"

import styles from "highline/styles/components/image_cta.module.css"

const ImageCta = ({ altText, imageUrl, height, link, onClickCTA, metadata, width }) => {
  if (!altText) {
    return null
  }

  const image = (
    <NextImages
      src={imageUrl}
      alt={altText}
      ariaLabel={altText}
      wrapperClassNames={[styles.image]}
      height={height}
      width={width}
      object-fit="contain"
      object-position="center"
    />
  )

  const content = <div style={{ width }}>{image}</div>

  return (
    <div className={classNames("component", "image-cta-component", styles.component)}>
      {!link.isEmpty() && (
        <Link
          href={link.get("href")}
          as={link.get("as")}
          onClick={() => onClickCTA(altText, link, imageUrl, metadata)}
        >
          {content}
        </Link>
      )}
      {link.isEmpty() && (
        <button aria-label={altText} onClick={() => onClickCTA(altText)}>
          {content}
        </button>
      )}
    </div>
  )
}

ImageCta.propTypes = {
  height: PropTypes.number,
  imageUrl: PropTypes.string,
  layout: PropTypes.oneOf(["light", "dark", "large"]),
  lazyLoad: PropTypes.bool,
  link: ImmutablePropTypes.map,
  altText: PropTypes.string,
  onClickCTA: PropTypes.func,
  text: PropTypes.string,
  maxWidth: PropTypes.number,
  metadata: PropTypes.map,
  width: PropTypes.number,
}

ImageCta.defaultProps = {
  altText: "",
  height: 350,
  imageUrl: "",
  layout: "light",
  lazyLoad: false,
  link: Map({}),
  onClickCTA: () => {},
  text: "",
  maxWidth: 1,
  metadata: Map({}),
}

export default ImageCta
