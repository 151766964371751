import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import styles from "highline/styles/components/tooltip/tooltip_dropdown.module.css"

const TooltipDropdown = ({ children, style }) => (
  <div className={classNames("component", "tooltip-dropdown-component", styles.component, style)}>
    {children}
  </div>
)

TooltipDropdown.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
}

export default TooltipDropdown
