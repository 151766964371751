/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import Head from "next/head"
import dynamic from "next/dynamic"
import { getField, getObjectByFirstField } from "highline/utils/contentful/contentful_helper"
import { contentfulComponentClicked } from "highline/redux/actions/contentful_actions"
import { renderContentfulComponent } from "highline/utils/contentful/component_helper"
import Link from "highline/components/secure_link"
import HeaderV2Container from "highline/containers/header_v2_container"
import LeftDrawerContainer from "highline/containers/left_drawer_container"
import Footer from "highline/components/application/footer"

import styles from "highline/styles/components/error_page/custom_error.module.css"

const DynamicRightDrawerContainer = dynamic(
  () => import("highline/containers/right_drawer_container")
)

const messages = {
  401: "Sorry, you are not authorized to access this resource",
  404: "Sorry, the page you were looking for could not be found.",
  409: "Sorry, cart is currently being modified",
  default: "This page needs some tailoring...",
}

const CustomError = ({ statusCode, pageCategory, title, message }) => {
  const dispatch = useDispatch()
  const contentfulAnalyticsCallback = (contentType, target, contentId) => {
    dispatch(contentfulComponentClicked(contentType, target, contentId))
  }
  const contentfulData = useSelector((state) => state.getIn(["contentful", "globals"]))
  const contentfulErrorPageData = getField(
    getObjectByFirstField(contentfulData, "Error Page Content"),
    "content"
  )
  const shouldShowErrorPageData = contentfulErrorPageData && !contentfulErrorPageData.isEmpty()

  return (
    <div id="layout-wrapper" data-page-category={pageCategory}>
      <Head>
        <title key="title">{title}</title>
      </Head>

      <HeaderV2Container />

      <LeftDrawerContainer />

      <DynamicRightDrawerContainer />

      <div
        className={classNames(
          "component",
          "error-component",
          styles.component,
          styles.center,
          styles.secondary
        )}
      >
        <section className={styles.copy}>
          <h1 className={styles.title}>{message || messages[statusCode] || messages.default}</h1>
          {statusCode >= 500 && (
            <h2 className={styles.description}>
              We apologize but we’re experiencing an internal server problem. Please check back soon
              or contact us at
              <a
                aria-label="Open a new email to guides@bonobos.com"
                href="mailto:guides@bonobos.com"
              >
                {" "}
                guides@bonobos.com{" "}
              </a>
              for immediate assistance.
            </h2>
          )}
          <div className={styles.ctaContainer}>
            <Link href="/" key="Homepage" aria-label="Navigate to Homepage" className={styles.cta}>
              Return to the Homepage
            </Link>
          </div>
        </section>
        <div className={styles.contentBlock}>
          {shouldShowErrorPageData &&
            contentfulErrorPageData.map((component, index) =>
              renderContentfulComponent(
                component,
                contentfulAnalyticsCallback,
                `error-page-content-${index}`
              )
            )}
        </div>
      </div>
      <Footer showEmailForm={false} />
    </div>
  )
}

CustomError.propTypes = {
  pageCategory: PropTypes.string,
  statusCode: PropTypes.number,
  title: PropTypes.string,
  message: PropTypes.string,
}

const MemoizedCustomError = memo(CustomError)
MemoizedCustomError.displayName = "CustomError"

export default MemoizedCustomError
