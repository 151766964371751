/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "highline/styles/components/nav_item.module.css"

const NavItem = ({ children, decoration, isActive, layout, onClick, className }) => (
  <div
    className={classNames(
      "component",
      "nav-item-component",
      styles.component,
      styles[layout],
      styles[decoration],
      isActive ? styles.active : null,
      className
    )}
    onClick={onClick}
  >
    {children}
  </div>
)

NavItem.propTypes = {
  children: PropTypes.node,
  decoration: PropTypes.oneOf(["dark", "accent"]),
  isActive: PropTypes.bool,
  layout: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

NavItem.defaultProps = {
  decoration: "accent",
  isActive: false,
  onClick: () => {},
  className: "",
}

export default NavItem
